import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    FaArrowLeft
} from 'react-icons/fa'
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import {loadingHandler} from "../../components/Loading"
import { Construct } from "../../components/Constuct";
import Sidebar from "../../components/Sidebar";
import { settingConfig } from "../../components/PageConfig";



const NewPromoInternet = () => {
    const [allRole, setAllRole] = useState([])
    const [namaPromo, setNamaPromo] = useState("")
    const [bulanPromo, setBulanPromo] = useState("")
    const [no_telp, setNoTelp] = useState("")
    const [password, setPassword] = useState("")
    const [id_role, setIdRole] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const condition = "newpromointernet"


    const location = useLocation()
    const navigate = useNavigate()
    const {state} = location

    useEffect(() => {
        getAllROle()
    },[])

    useEffect(() => {
        loadingHandler(isLoading)
    })

    const getAllROle = () => {
        axios.get(baseUrl+'getallrole')
        .then(res => {
            setAllRole(res.data)
        })
    }

    const saveNewPromo = (e) => {
        e.preventDefault()
        setIsLoading(true)
        
        if(condition === "newpromoipl"){
            axios.post(baseUrl+"promoipl", {
                nama_promoipl: namaPromo,
                bulan_promoipl: bulanPromo
            }).then(res => {
                console.log(res.data);
                setIsLoading(false)
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    setNamaPromo("")
                    setBulanPromo("")
                }
              })
            }).catch(err => {
                console.log(err);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.msg,
                        allowOutsideClick: false
                      }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(false)
        
                        }
                      })
            })
        }else if(condition === "newpromointernet"){
            axios.post(baseUrl+"promointernet", {
                nama_promointernet: namaPromo,
                bulan_promointernet: bulanPromo
            }).then(res => {
                console.log(res.data);
                setIsLoading(false)
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    setNamaPromo("")
                    setBulanPromo("")
                }
              })
            }).catch(err => {
                console.log(err);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.msg,
                        allowOutsideClick: false
                      }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(false)
        
                        }
                      })
            })
        }

        

    }

        return(
            <Sidebar menuItems={settingConfig}>
            <main id='newcustomer-container' >
                <form>
                <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/setting")}} />
                
                <h4>Mazenta Residence</h4>
                <h2>Promo Internet</h2>
                
                    <div class="column" style={{marginBottom: "25px"}}>
                        <label for="name">Nama Promo: </label>
                        <input value={namaPromo} onChange={(e) => {setNamaPromo(e.target.value)}}  type="text" id="no-nup" placeholder="Nama Promo IPL"/>
                    </div>
                    <div class="column">
                        <label for="unitcode" >Bulan Promo: </label>
                        <input value={bulanPromo} onChange={(e) => {setBulanPromo(e.target.value)}}  type="number" id="no-cusomer" placeholder="Bulan Promo IPL"/>
                    </div>
                
                
                
    
               
    
    
                
                
                <button onClick={(e) => {saveNewPromo(e)}} >Submit</button>
            </form>
            </main>
            </Sidebar>
        )
   


}

export default NewPromoInternet;