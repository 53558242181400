import React, {useState } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
    FaFileInvoice,
    FaHome
}from "react-icons/fa";
import Swal from "sweetalert2";


import {BsGraphUp} from "react-icons/bs"
import {BiLogOut, BiReceipt} from "react-icons/bi"
import {AiOutlineSetting} from "react-icons/ai"


import { NavLink } from 'react-router-dom';
import './Sidebar.css'

import logoPict from '../Assets/CHL-Logo2.png'
import {useNavigate, useLocation} from 'react-router-dom'
import { MdArrowDropDown } from 'react-icons/md';


const Sidebar = ({children, menuItems}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const navigate = useNavigate()

    
    console.log(menuItems);

    // const menuItem=[
    //     {
    //         path:"/",
    //         name:"Dashboard",
    //         icon:<FaTh/>
    //     },
    //     {
    //         path:"/sales",
    //         name:"Sales",
    //         icon:<BsGraphUp/>
    //     },
    //     {
    //         path:"/estate",
    //         name:"Estate",
    //         icon:<FaFileInvoice/>
    //     },
    //     {
    //         path: "/reporting",
    //         name: "Report",
    //         icon: <BiReceipt/>
    //     }
       
    // ]

    const redirectHandler = () =>{
        

        return new Promise((resolve, reject) => {
            navigate("/")
            const error = false
      if(!error) {
        resolve()
      }else{
        reject()
      }
         })
    }

    const removeToken = () => {

         return new Promise((resolve, reject) => {
            sessionStorage.removeItem("TokenCHL")
            const error = false
      if(!error) {
        resolve()
      }else{
        reject()
      }
         })
        
   }


    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.isConfirmed) {
             removeToken()
             .then(redirectHandler().then(window.location.reload()))
             .catch(error => console.log(error))
                
            }
          })
        
    }

    const toogleHandler = (e) => {
      const sublink = document.querySelectorAll('.expand'+e)
      console.log(sublink);
      sublink.forEach(sublink => sublink.classList.toggle('slide'));
    }



    return (
        <div className="container s">
            
            
            <div className='layout' >
            <div  className="sidebar">
              
                <div className="top_section">
                    <FaHome  className='home-direct' size={50} onClick={() => {navigate(`/`)}} />  
               </div>

                <div style={{background: menuItems.title.color}} className='sidebar-title' >
                    {menuItems.title.icon}
                    <h2>{menuItems.title.text}</h2>
                </div>

               <div className='nav-group' >

                    <div className='navlink-group' >
                      {
                        menuItems.menu.map((item, index) => {
                          return(
                            <>
                            <div key={index} onClick={() => {toogleHandler(index)}} className='link'>
                              <div className='icon'>{item.icon}</div>
                              <div className="link_text">{item.name}</div>
                              <div className='icon'><MdArrowDropDown size={20}/></div>
                            </div>
                            {item.subMenu.map((subitem, subindex) => {
                                return(
                                  <NavLink to={subitem.path} state={{condition: subitem.props}} key={subindex} className={`sublink expand${index}`}  activeclassname="active">
                                    <div className="icon">{subitem.icon}</div>
                                    <div className="link_text">{subitem.name}</div>
                                  </NavLink>
                                )
                              })}
                            </>
                          )
                        })
                      }
                    {/* {
                   menuItems.menu.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div className="link_text">{item.name}</div>
                       </NavLink>
                    ))
                    } */}
                    </div>

                    

               </div>
               
                          
                       
           </div>
           <main>{children}</main>
            </div> 
           
        </div>
    );
};

export default Sidebar;



//Trash
// {/* <div  className=" bot ">
//                         <div onClick={() => {navigate('/setting')}} activeclassName="active" className='logout nav-bot' >
//                             <div className="icon"><AiOutlineSetting/></div>
//                            <div  className="link_text"style={{display: isOpen ? "block" : "none"}}>Setting</div>
//                         </div>
//                         <div activeclassName="active" onClick={logout} className='logout nav-bot' >
//                             <div className="icon"><BiLogOut/></div>
//                            <div  className="link_text"style={{display: isOpen ? "block" : "none"}}>Logout</div>
//                         </div>
                            
//                     </div> */}


// {
//     menuItem.map((item, index)=>(
//         <NavLink to={item.path} key={index} className="link" activeclassName="active">
//             <div className="icon">{item.icon}</div>
//             <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
//         </NavLink>
//     ))
// }
//          <div className="link logout" activeclassName="active">
//              <div className="icon">icon</div>
//             <div  className="link_text">Logout</div>
//          </div>