import React, { useEffect, useState, useRef } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import { loadingHandler } from "../../components/Loading";
import Swal from "sweetalert2";

import './NewInvoice.css'
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";
import { AiFillCloseCircle } from "react-icons/ai";

const NewInvoice = () => {
    const [custData, setCustData] = useState("")
    const [promoDataIpl, setPromoDataIpl] = useState([])
    const [promoDataInternet, setPromoDataInternet] = useState([])


    const [price, setPrice] = useState("")
    const [qty, setQty] = useState(0)
    const [qtyAddon, setQtyAddon] = useState(0)
    const [penalty, setPenalty] = useState(0)
    const [discountIplPercent, setDiscountIplPercent] = useState(0)
    const [discountIplPercentToNominal, setDiscountIplPercentToNominal] = useState(0)
    const [discountIplNominal, setDiscountIplNominal] = useState(0)
    const [discountIplNominalToPercent, setDiscountIplNominalToPercent] = useState(0)
    const [dendaIpl, setDendaIpl] = useState(0)
    const [ppnIpl, setPpnIpl] = useState(0)
    const [promoIpl, setPromoIpl] = useState({id:null, nama_promoipl: "", bulan_promoipl:0})
    const [promoIplNominal, setPromoIplNominal] = useState(0)
    const [promoIplPercent, setPromoIplPercent] = useState(0)
    const [discountPlus, setDiscountPlus] = useState(0)
    const [discountInternetPercent, setDiscountInternetPercent] = useState(0)
    const [discountInternetNominal, setDiscountInternetNominal] = useState(0)
    const [dendaInternet, setDendaInternet] = useState(0)
    const [promoInternet, setPromoInternet] = useState({id:null, nama_promointernet: "", bulan_promointernet:0})
    const [ppnInternet, setPpnInternet] = useState(0)
    const [addOn, setAddOn] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [iplActive, setIplActive] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [ppnInvoice, setPpnInvoice] = useState(0)
    const [tgl_jatuhtempo, setTglJatuhtempo] = useState("")
    const [search, setSearch] = useState("")
    const [startipl_invoice, setStartIplInvoice] = useState(null)
    const [endipl_invoice, setEndIplInvoice] = useState(null)
    const [startinternet_invoice, setStartInternetInvoice] = useState(null)
    const [endinternet_invoice, setEndInternetInvoice] = useState(null)

    const [showForm, setShowForm] = useState("ipl")

    const navigate = useNavigate()
    const checkbox = useRef()
        
    useEffect(() => {
        getIplActiveHandler()
        getAllPromoIpl()
        getAllPromoInternet()
        getLastDate()
    },[])

    useEffect(() => {
        loadingHandler(isLoading)
    })

    const getLastDate = () => {
        // Dapatkan tanggal saat ini
        var today = new Date();

        // Dapatkan tahun dan bulan saat ini
        var currentYear = today.getFullYear();
        var currentMonth = today.getMonth() + 1; // Perlu ditambah 1 karena bulan dimulai dari 0 (0 = Januari, 1 = Februari, ..., 11 = Desember)

        // Atur tanggal ke awal bulan berikutnya
        today.setMonth(today.getMonth() + 1, 1);

        // Kurangi 1 hari untuk mendapatkan tanggal terakhir dari bulan ini
        today.setDate(today.getDate() - 1);

        // Dapatkan tanggal terakhir dari bulan ini
        var lastDayOfMonth = today.getDate();
        setTglJatuhtempo(`${currentYear}-${currentMonth}-${lastDayOfMonth}`)
        console.log(`${currentYear}-${currentMonth}-${lastDayOfMonth}`);
        console.log("Tanggal terakhir dari bulan ini: " + lastDayOfMonth);
        console.log("Bulan saat ini: " + currentMonth);
        console.log("Tahun saat ini: " + currentYear);
    }

    const getAllPromoIpl = () => {
        axios(baseUrl+"getallpromoipl")
        .then(res => {
            // console.log(res.data);
            setPromoDataIpl(res.data)
        })
    }

    const getAllPromoInternet = () => {
        axios(baseUrl+"getallpromointernet")
        .then(res => {
            // console.log(res.data);
            setPromoDataInternet(res.data)
        })
    }

    const getIplActiveHandler = () => {
        axios.get(baseUrl+'getiplactive')
        .then(res => {
            // console.log(res.data);
            setIplActive(res.data)
        }).catch(err => {
            // console.log(err);
        })
    }

    const searchIplActive = () => {
        axios.post(baseUrl+"searchiplactive",{
            nama:search
        })
        .then(res => {
            // console.log(res.data);
            setIplActive(res.data)
        }).catch(err => {
            // console.log(err);
        })
    }

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        setCustData(e)
        // console.log(e);
        
      }
      
    const  closeModalHandler = () => {
        setQty(0)
        setCustData("")
        setDiscountIplPercent(0)
        setDiscountPlus(0)
        setQtyAddon(0)
        setTglJatuhtempo("")
        setDiscountIplNominal(0)
        setPromoIpl({id:null, nama_promoipl: "", bulan_promoipl:0})
        setPromoInternet({id:null, nama_promointernet: "", bulan_promointernet:0})
        setDendaIpl(0)
        setDendaInternet(0)
        setStartInternetInvoice(null)
        setStartIplInvoice(null)
        setEndInternetInvoice(null)
        setEndIplInvoice(null)
        // console.log(qty);
        document.getElementById("profile-modal").style.display = "none"
        document.getElementsById("ppn-check").checked = false
        
        
      }

      
    const saveNewInvoice = () => {
        // setIsLoading(true)
        // console.log(custData.nama);
        // console.log(custData.nominal);
        // console.log(custData.id_customer);
        // console.log(qty);
        // console.log(penalty);
        // console.log(Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))+Number(penalty)-Number(discountPlus)+Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountInternetPercent/100)));
        const id_customer = custData.id_customer
        const nama_customer = custData.nama
        const no_unit_invoice = custData.no_unit
        const no_telp_invoice = custData.no_hp1
        const no_va_invoice = custData.no_va
        const email_invoice = custData.alamat_email
        const id_ipl = custData.id_ipl
        const id_promoipl = promoIpl.id
        const id_promointernet = promoInternet.id

        //IPL Input Requirement 
        const ipl_invoice = custData.nominal
        const kuantitas_ipl = qty
        const diskonipl_persen = discountIplPercent
        const diskonipl_persentonominal = Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))
        const diskonipl_nominal = discountIplNominal
        const diskonipl_nominaltopersen = qty*custData.nominal > 0 ?  Math.min((discountIplNominal*100)/Number(custData.nominal*qty)).toFixed(1) : (0).toFixed(1)
        const promo_iplnominal = promoIpl.bulan_promoipl*custData.nominal
        const promo_iplpersen = qty*custData.nominal > 0 ? Math.min(((promoIpl.bulan_promoipl*custData.nominal)*100)/(custData.nominal*qty)).toFixed(1) : (0).toFixed(1)
        const bulanpromoipl_invoice = promoIpl.bulan_promoipl
        const namapromoipl_invoice = promoIpl.nama_promoipl
        const ppn_ipl = ppnIpl
        const ppn_iplnominal = Math.floor((Number(custData.nominal*qty)-Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))-Number(custData.nominal*promoIpl.bulan_promoipl)-discountIplNominal)*(ppnIpl/100))
        const denda_ipl = dendaIpl
        const total_ipl = Math.floor(Number(custData.nominal*qty)-Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))-Number(custData.nominal*promoIpl.bulan_promoipl)-discountIplNominal)


        //Internet Input Requirement
        const internet_invoice = custData.tarif_internet
        const kuantitas_internet = qtyAddon
        const diskoninternet_persen = discountInternetPercent
        const diskoninternet_persentonominal = Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))
        const diskoninternet_nominal = discountInternetNominal
        const diskoninternet_nominaltopersen = qtyAddon*custData.tarif_internet > 0 ? Math.min((discountInternetNominal*100)/Number(custData.tarif_internet*qtyAddon)).toFixed(1) : (0).toFixed(1)
        const promo_internetnominal = promoInternet.bulan_promointernet*custData.tarif_internet
        const promo_internetpersen = qtyAddon*custData.tarif_internet > 0  ? Math.min(((promoInternet.bulan_promointernet*custData.tarif_internet)*100)/(custData.tarif_internet*qtyAddon)).toFixed(1) : (0).toFixed(1)
        const bulanpromointernet_invoice = promoInternet.bulan_promointernet
        const namapromointernet_invoice = promoInternet.nama_promointernet
        const ppn_internet = ppnInternet
        const ppn_internetnominal = Math.floor((Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))-Number(custData.tarif_internet*promoInternet.bulan_promointernet)-discountInternetNominal)*Number(ppnInternet/100))
        const denda_internet = dendaInternet
        const total_internet = Math.floor(Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))-Number(custData.tarif_internet*promoInternet.bulan_promointernet)-discountInternetNominal)
        const speed_internet = custData.speed_internet
        const status_invoice = 0
        

        const grandtotal_invoice = grandTotalHandler()

        const penalty_invoice = penalty
        
        
        // const potongan_ipl = Math.floor(Number(ipl_invoice*qty)*Number(diskonipl_persen/100))
        // const potongan_tambahan = discountPlus
        // const potongan_internet = Math.floor(Number(custData.tarif_internet*qtyAddon)*Number(diskoninternet_persen/100))
        // const subtotal = Number(total_internet+total_ipl-potongan_tambahan)
        
        // const ppn_invoice = Math.floor(Number(subtotal*ppnInvoice))
        

        const promo_ipl = promoIpl.bulan_promoipl
        const promo_internet = promoInternet

    //    console.log("int__"+promo_internetnominal);
    //    console.log("int__"+promo_internetpersen);
    //    console.log("int__"+qtyAddon);

    //    console.log("ipl__"+promo_iplnominal);
    //    console.log("ipl__"+promo_iplpersen);
    //    console.log("ipl__"+qty);

    //    console.log(diskoninternet_nominaltopersen);

       axios.get(baseUrl+'checknoinvoice')
       .then(res => {
        //console.log(res.data.last_no_invoice);
        const no_invoice = res.data.last_no_invoice + 1
        
        axios.post(baseUrl+"savenewinvoice", {
            nama_customer,
            no_unit_invoice,
            no_telp_invoice,
            no_va_invoice,
            no_invoice,
            email_invoice,
            ipl_invoice,
            kuantitas_ipl,
            diskonipl_persen,
            diskonipl_persentonominal,
            diskonipl_nominal,
            diskonipl_nominaltopersen,
            promo_iplnominal,
            promo_iplpersen,
            bulanpromoipl_invoice,
            namapromoipl_invoice,
            ppn_ipl,
            ppn_iplnominal,
            denda_ipl,
            total_ipl,
            internet_invoice,
            kuantitas_internet,
            diskoninternet_persen,
            diskoninternet_persentonominal,
            diskoninternet_nominal,
            diskoninternet_nominaltopersen,
            promo_internetnominal,
            promo_internetpersen,
            bulanpromointernet_invoice,
            namapromointernet_invoice,
            ppn_internet,
            ppn_internetnominal,
            denda_internet,
            total_internet,
            grandtotal_invoice,
            tgl_jatuhtempo,
            speed_internet,
            startipl_invoice,
            endipl_invoice,
            startinternet_invoice,
            endinternet_invoice,
            status_invoice
        }).then(res => {
            //console.log(res.data);
            setIsLoading(false)
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    closeModalHandler()
                }
              })
        }).catch(err => {
            setIsLoading(false)
            //console.log(err.response.data.msg);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })


       })
       

        // console.log(ppnInternet);
        // console.log(ppnIpl);
        
        
        // console.log(denda_ipl);
        // console.log(denda_internet);
        // console.log(ppn_ipl);
        // console.log(ppn_internet);
        // console.log(promo_ipl);
        // console.log(promo_internet);
        // console.log(diskoninternet_nominal);
        // console.log(diskonipl_nominal);
        // console.log(Math.min((discountIplNominal*100)/Number(custData.nominal*qty)).toFixed(1)+"%");
        // console.log(Math.min((discountInternetNominal*100)/Number(custData.tarif_internet*qtyAddon)).toFixed(1)+"%");
        // console.log("potonganIPL__"+potongan_ipl);
        // console.log("Prtonganaddon"+potongan_internet);
        // console.log("totalIPL__"+total_ipl);
        // console.log("totaladdon_"+total_internet);
        // console.log("subtotal"+subtotal);
        // console.log("ppn__"+ppn_invoice);
        // console.log("penalty__"+penalty_invoice);
        // console.log("grandtotal__"+grandtotal_invoice);
        // console.log("nominalInvoice__"+ipl_invoice);
        // console.log("kuantitasipl__"+kuantitas_ipl);
        // console.log("kuantiasaddon__"+kuantitas_internet);
        // console.log("addoninvoice__"+internet_invoice);
        // console.log("diskonIPlper__"+diskonipl_persen);
        // console.log("diskonaddonpres__"+diskoninternet_persen);
        // console.log("potongantambahan__"+potongan_tambahan);
        // console.log("idcustomer__"+id_customer);
        // console.log("idipl__"+id_ipl);
        // console.log("ppn__"+ppn_invoice);

        // console.log(total_ipl);
        // console.log(total_add0n);

        // axios.post(baseUrl+"savenewinvoice", {
        //     id_ipl,
        //     nama_customer,
        //     no_unit_invoice,
        //     no_telp_invoice,
        //     no_va_invoice,
        //     email_invoice,
        //     id_customer,
        //     ipl_invoice,
        //     kuantitas_ipl,
        //     diskonipl_persen,
        //     diskonipl_persentonominal,
        //     diskonipl_nominal,
        //     diskonipl_nominaltopersen,
        //     promo_iplnominal,
        //     promo_iplpersen,
        //     bulanpromoipl_invoice,
        //     namapromoipl_invoice,
        //     ppn_ipl,
        //     ppn_iplnominal,
        //     denda_ipl,
        //     total_ipl,
        //     internet_invoice,
        //     kuantitas_internet,
        //     diskoninternet_persen,
        //     diskoninternet_persentonominal,
        //     diskoninternet_nominal,
        //     diskoninternet_nominaltopersen,
        //     promo_internetnominal,
        //     promo_internetpersen,
        //     bulanpromointernet_invoice,
        //     namapromointernet_invoice,
        //     ppn_internet,
        //     ppn_internetnominal,
        //     denda_internet,
        //     total_internet,
        //     grandtotal_invoice,
        //     tgl_jatuhtempo,
        //     speed_internet,
        //     startipl_invoice,
        //     endipl_invoice,
        //     startinternet_invoice,
        //     endinternet_invoice,
        //     status_invoice
        // }).then(res => {
        //     console.log(res.data);
        //     setIsLoading(false)
        //     Swal.fire({
        //         title: 'Success',
        //         text: res.data.msg,
        //         icon: 'success',
        //         showConfirmButton: true,
        //         confirmButtonColor: '#3085d6',
        //         confirmButtonText: 'Ok'
        //       }).then((result) => {
        //         if (result.isConfirmed) {
        //             setIsLoading(false)
        //             closeModalHandler()
        //         }
        //       })
        // }).catch(err => {
        //     setIsLoading(false)
        //     console.log(err.response.data.msg);
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: err.response.data.msg,
        //         allowOutsideClick: false
        //       }).then((result) => {
        //         if (result.isConfirmed) {
        //             setIsLoading(false)

        //         }
        //       })
        // })

    }

    const checkNum = (e, fun) => {
        // console.log(e);
        if(e === undefined || e <= 0){
            fun(0)
        }else{
            fun(parseInt(e))
        }

        if(fun === setQty){
            handleStartIplChange(startipl_invoice)
        }

        if(fun === setQtyAddon){
            handleStartInternetChange(startinternet_invoice)
        }
    }

    const valuePPnHandler = () => {
        const subTotal = Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discountIplPercent/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountInternetPercent/100))

        return Math.floor(subTotal*Number(ppnInvoice))
    }

    const valueIplPPnHandler = () => {
        const data = Number(custData.nominal*qty)-Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))-Number(custData.nominal*promoIpl.bulan_promoipl)-discountIplNominal
        return Math.floor(data*Number(ppnIpl/100))
    }

    const valueInternetPPnHandler = () => {
        const data = Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))-Number(custData.tarif_internet*promoInternet.bulan_promointernet)-discountInternetNominal
        return Math.floor(data*Number(ppnInternet/100))
    }

    const checkPpnHandler = (e, fun) => {
        if(e){
            fun(11)
        }else{
            fun(0)
        }
    }

    const grandTotalHandler = () => {
        const subTotal = Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discountIplPercent/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountInternetPercent/100))
        const afterPPN = subTotal*ppnInvoice

        const ipl = Number(custData.nominal*qty)-Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))-Number(custData.nominal*promoIpl.bulan_promoipl)-discountIplNominal
        const internet = Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))-Number(custData.tarif_internet*promoInternet.bulan_promointernet)-discountInternetNominal
         return (ipl+internet+valueIplPPnHandler()+valueInternetPPnHandler()+dendaInternet+dendaIpl)


    }

    const handleStartIplChange = (e) => {
        const startDate = new Date(e);
        const newEndDate = new Date(startDate);
        
        console.log(e);

        // Set the end date based on qty
        newEndDate.setMonth(newEndDate.getMonth() + (qty-1));

        // Check if start date is end of month
  const isEndOfMonth = startDate.getDate() === new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();

  // If start date is end of month, set end date to end of month
  if (isEndOfMonth) {
    newEndDate.setDate(new Date(newEndDate.getFullYear(), newEndDate.getMonth() + 1, 0).getDate());
  }

    
        // Format the end date as "YYYY-MM-DD"
        const formattedEndDate = newEndDate.toISOString().split('T')[0];
        console.log(formattedEndDate);
        setStartIplInvoice(e);
        if(qty > 1){
            setEndIplInvoice(formattedEndDate);
        }else{
            setEndIplInvoice(null)
        }
        
      };

    const handleStartInternetChange = (e) => {
        const startDate = new Date(e);
        const newEndDate = new Date(startDate);
        
        console.log(e);

        // Set the end date based on qty
        newEndDate.setMonth(newEndDate.getMonth() + (qtyAddon-1));
    
        // Format the end date as "YYYY-MM-DD"
        const formattedEndDate = newEndDate.toISOString().split('T')[0];
        console.log(formattedEndDate);
        setStartInternetInvoice(e);
        if(qtyAddon > 1){
            setEndInternetInvoice(formattedEndDate);
        }else{
            setEndInternetInvoice(null)
        }
        
      };
    
      const handleQtyChange = (e) => {
        const newQty = parseInt(e.target.value, 10);
        setQty(newQty);
    
        // Update endIpl_invoice if startIpl_invoice is already set
        if (startipl_invoice) {
          const startDate = new Date(startipl_invoice);
          const newEndDate = new Date(startDate);
          newEndDate.setMonth(newEndDate.getMonth() + newQty);
    
          const formattedEndDate = newEndDate.toISOString().split('T')[0];
          setEndIplInvoice(formattedEndDate);
        }
      };
    
   
    return(
        <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Tagihan Invoice</h2>
                    <section className="search-group" >

                        <input onChange={(e) => {setSearch(e.target.value)}} type="search" placeholder="Cari No. Unit/Nama" className="search-box" />
                        <button onClick={searchIplActive} className="search-button" >Cari</button>
                        <button onClick={getIplActiveHandler} className="search-button" >Reset</button>

                    </section>
                    
                    <section className="list-antrian" >

                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th className="no">No.</th>
                            <th>No. Unit</th>
                            <th>Customer</th>
                            <th>IPL Rate</th>
                            <th>Periode Awal IPL</th>
                            <th>Periode Akhir IPL</th>
                            <th>Internet Rate</th>
                            <th>Periode Awal Internet</th>
                            <th>Periode Akhir internet</th>
                            <th>Alamat Email</th>
                            <th>No. Hp</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {iplActive.map((item, index) => {
                                return(
                                    <tr onClick={() => {openModalHandler(item)}}>
                                        <td className="no">{index+1}</td>
                                        <td>{item.no_unit}</td>
                                        <td>{item.nama}</td>
                                        <td>Rp.{item.nominal.toLocaleString("IN")}</td>
                                        <td>{item.periode_start.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        <td>{item.periode_end.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        <td>Rp.{item.tarif_internet.toLocaleString('IN')}</td>
                                        <td>{ item.periodestart_internet !== null ? item.periodestart_internet.replace(/(\d+)(st|nd|rd|th)/, "$1") : "-"}</td>
                                        <td>{ item.periodeend_internet !== null ? item.periodeend_internet.replace(/(\d+)(st|nd|rd|th)/, "$1") : "-"}</td>
                                        <td>{item.alamat_email}</td>
                                        <td>+{item.no_hp1}</td>
                                    </tr>
                                )
                            })}

                            
                        </tbody>
                    </table>
                    {iplActive.length === 0 && <h3>Belum Ada Data</h3>}


                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >

                    

                    <div className = "profile-modalContent" >
                    <div className="close-button"><AiFillCloseCircle onClick = {closeModalHandler} color="red" size={30} /></div>
                    <h1>Tagihan Invoice</h1>
                    {/* <h1>{custData.nama}</h1> */}
                    <div className = "profile-editForm" >
                    
                        <div className = "form-button" > 
                            <button onClick={() => (setShowForm("ipl"))} className = {`tab  ${showForm === "ipl" ? 'active' : ''}`}>IPL</button>
                            <button onClick={() => (setShowForm("internet"))} className = {`tab  ${showForm === "internet" ? 'active' : ''}`}>Internet</button>
                        </div>

                    {
                        showForm === "ipl"
                        ?
                        <>
                        <h3 style={{fontSize: "24px", fontWeight: "bolder", borderBottom: "3px black solid"}}>IPL</h3>

                        <div className="input-childgroup" >
                            <div>
                                <label>Customer</label>
                                <input disabled value = {custData.nama} />
                            </div>
                            <div>
                                <label>Harga (IPL Rate) :  </label>
                                <CurrencyInput
                                    disabled    
                                    id="input-example"
                                    name="input-name"
                                    placeholder="0"
                                    decimalSeparator="."
                                    value={custData.nominal}
                                    />
                            </div>
                        </div>

                        
                        
                        {/* <input type="number"  value={price} onChange={(e) => {setPrice(parseInt(e.target.value))}}  /> */}
                        <label>Kuantitas IPL (Bulan): </label>
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder={qty}
                            value={qty}
                            onValueChange={(e) => {checkNum(e, (setQty))}}
                            />
                            
                            <label>Periode Pembayaran IPL :</label>
                            <div className="date-name" style={{display:"flex", alignItems: "baseline", justifyContent:"space-evenly"}} >
                                <input value={startipl_invoice} onChange={(e) => {handleStartIplChange(e.target.value)}} type="date" style={{width: "45%"}} />
                                    <label>To</label>
                                <input value={endipl_invoice || ""} disabled  onChange={(e) => {setEndIplInvoice(e.target.value)}} type="date" style={{width: "45%"}} />
                            </div>
                            
                        <label>Diskon IPL (%): </label>
                        <div className="input-childgroup" > 
                            <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={discountIplPercent}
                                onValueChange={(e) => {checkNum(e, (setDiscountIplPercent))}}
                                />
                            <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))}
                                disabled
                                />
                        </div>
                        

                        <label>Diskon IPL (Nominal): </label>
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            decimalSeparator="."
                            placeholder={0}
                            value={discountIplNominal}
                            onValueChange={(e) => {checkNum(e, (setDiscountIplNominal))}}
                            />

                        <div className="input-childgroup">

                            <div>
                                <label>Promo IPL: </label>
                            
                                <select onChange= {e => setPromoIpl(JSON.parse(e.target.value))}>
                                    <option value={JSON.stringify({id:null, nama_promoipl: "", bulan_promoipl:0})}>--Select--</option>
                                    {promoDataIpl.map((item) => {
                                        return(
                                            <>
                                                <option value={JSON.stringify(item)} >{item.nama_promoipl}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div>
                            <div>
                                <label>Jumlah Bulan Promo IPL</label>
                                <CurrencyInput
                                    id="input-example"
                                    name="input-name"
                                    decimalSeparator="."
                                    placeholder={0}
                                    value={promoIpl.bulan_promoipl}
                                    disabled
                                    />
                            </div>
                            <div>
                                <label>Persen Promo IPL</label>
                                <input
                                id="input-example"
                                name="input-name"
                                value={qty > 0 ? Math.min(((promoIpl.bulan_promoipl*custData.nominal)*100)/(custData.nominal*qty)).toFixed(1) : (0).toFixed(1)}
                                disabled
                                />
                            </div>
                            <div>
                                <label>Nominal Promo IPL</label>
                                <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={promoIpl.bulan_promoipl*custData.nominal}
                                disabled
                                />
                            </div>

                        </div> 
                        
                        <label>PPN IPL (11%):</label>
                        <div className="input-childgroup">
                            <div className="check-group"  >
                                <input onChange={(e) => {checkPpnHandler(e.target.checked, setPpnIpl)}} 
                                    id="ppn-check" 
                                    type="checkbox" 
                                    className="ppn-check"  />
                                {/* <label>Ceklist PPN</label> */}
                            </div>
                            <CurrencyInput
                            style={{width: "100px"}}
                                disabled    
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                value={valueIplPPnHandler()}
                                />
                        </div>

                        
                        

                        <label>Denda IPL (Nominal): </label>
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            decimalSeparator="."
                            placeholder={0}
                            value={dendaIpl}
                            onValueChange={(e) => {checkNum(e, (setDendaIpl))}}
                            />
                        {/* <input type="number" value={penalty} onChange={(e) => {setPenalty(parseInt(e.target.value))}} /> */}
                        {/* <label>Paket: </label>
                        <input type="text"/> */}
                        <label>Total IPL: </label>
                        <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            defaultValue={0}
                            value={Number(custData.nominal*qty)-Number(custData.nominal*(qty-promoIpl.bulan_promoipl)*Number(discountIplPercent/100))-Number(custData.nominal*promoIpl.bulan_promoipl)-discountIplNominal}
                            />
                            <h3>Tgl Jatuh Tempo:</h3>
                            <input disabled value={tgl_jatuhtempo} onChange={(e) => {setTglJatuhtempo(e.target.value)}} type = "date" />
                            
                            <h3>Grand Total: </h3>
                            <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            defaultValue={0}
                            value={grandTotalHandler()}
                            />
                            

                        <div className = "form-button" > 
                            <button onClick={saveNewInvoice} className = "profile-save btn ">Simpan</button>
                            <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                        </div>

                        </>
                        :
                        <>
                            <h3 style={{fontSize: "24px", fontWeight: "bolder", borderBottom: "3px black solid"}}>Internet:</h3>
                            <div className="input-childgroup" >
                                <div>
                                    <label>Customer</label>
                                    <input disabled value = {custData.nama} />
                                </div>
                                <div>
                                    <label>Harga (Internet Rate) :  </label>
                                    <CurrencyInput
                                    disabled    
                                    id="input-example"
                                    name="input-name"
                                    placeholder={0}
                                    decimalSeparator="."
                                    defaultValue={0}
                                    value={custData.tarif_internet}
                                    />
                                </div>
                            </div>
                        
                            <label>Kuantitas Internet (Bulan):</label>
                            <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder={qtyAddon}
                            value={qtyAddon}
                            onValueChange={(e) => {checkNum(e, (setQtyAddon))}}
                            />

                            <label>Periode Pembayaran Internet :</label>
                            <div className="date-name" style={{display:"flex", alignItems: "baseline", justifyContent:"space-evenly"}} >
                                <input value={startinternet_invoice || ""} onChange={(e) => {handleStartInternetChange(e.target.value)}} type="date" style={{width: "45%"}} />
                                    <label>To</label>
                                <input value={endinternet_invoice || ""} disabled onChange={(e) => {setEndInternetInvoice(e.target.value)}} type="date" style={{width: "45%"}} />
                            </div>

                            <label>Diskon Internet (%): </label>
                            <div className="input-childgroup">
                                <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={discountInternetPercent}
                                onValueChange={(e) => {checkNum(e, (setDiscountInternetPercent))}}
                                />
                                <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))}
                                disabled
                                />
                            </div>

                            

                            <label>Diskon Internet (Nominal): </label>
                            <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={discountInternetNominal}
                                onValueChange={(e) => {checkNum(e, (setDiscountInternetNominal))}}
                            />
                            
                            <div className="input-childgroup">

                                <div>
                                    <label>Promo Internet: </label>
                            
                                    <select onChange= {e => setPromoInternet(JSON.parse(e.target.value))}>
                                    <option value={JSON.stringify({id:null, nama_promointernet: "", bulan_promointernet:0})}>--Select--</option>
                                    {promoDataInternet.map((item) => {
                                        return(
                                            <>
                                                <option value={JSON.stringify(item)} >{item.nama_promointernet}</option>
                                            </>
                                        )
                                    })}
                                    </select>
                                </div>
                                <div>
                                    <label>Jumlah Bulan Promo IT</label>
                                    <CurrencyInput
                                        id="input-example"
                                        name="input-name"
                                        decimalSeparator="."
                                        placeholder={0}
                                        value={promoInternet.bulan_promointernet}
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Persen Promo Internet</label>
                                    <input
                                        id="input-example"
                                        name="input-name"
                                        value={qtyAddon*custData.tarif_internet > 0  ? Math.min(((promoInternet.bulan_promointernet*custData.tarif_internet)*100)/(custData.tarif_internet*qtyAddon)).toFixed(1) : (0).toFixed(1)}
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Nominal Promo Internet</label>
                                    <CurrencyInput
                                        id="input-example"
                                        name="input-name"
                                        decimalSeparator="."
                                        placeholder={0}
                                        value={promoInternet.bulan_promointernet*custData.tarif_internet}
                                        disabled
                                        />
                                </div>

                            </div>
                                
                            
                            
                            

                        
                        
                        
                            <label>PPN Internet (11%):</label>
                            <div className="input-childgroup" >
                                <div className="check-group"  >
                                    <input onChange={(e) => {checkPpnHandler(e.target.checked, setPpnInternet)}} 
                                        id="ppn-check" 
                                        type="checkbox" 
                                        className="ppn-check"  />
                                    
                                </div>
                                <CurrencyInput
                                    disabled    
                                    id="input-example"
                                    name="input-name"
                                    decimalSeparator="."
                                    value={valueInternetPPnHandler()}
                                    />
                            </div>
                            

                            <label>Denda Internet (Nominal): </label>
                            <CurrencyInput
                                id="input-example"
                                name="input-name"
                                decimalSeparator="."
                                placeholder={0}
                                value={dendaInternet}
                                onValueChange={(e) => {checkNum(e, (setDendaInternet))}}
                                />
                            <label>Total Internet: </label>
                            <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            defaultValue={0}
                            value={Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*(qtyAddon-promoInternet.bulan_promointernet)*Number(discountInternetPercent/100))-Number(custData.tarif_internet*promoInternet.bulan_promointernet)-discountInternetNominal}
                            />
                            {/* <h3>Diskon Tambahan</h3>
                            <CurrencyInput
                            id="input-example"
                            name="input-name"
                            decimalSeparator="."
                            placeholder={0}
                            value={discountPlus}
                            onValueChange={(e) => {checkNum(e, (setDiscountPlus))}}
                            />

                            <h3>Sub Total: </h3>
                            <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            defaultValue={0}
                            onValueChange={(e) => (setSubTotal(e)) }
                            value={Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discountIplPercent/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountInternetPercent/100))}
                            />
                            
                            <h3>PPN: 11%</h3>
                            <div className="check-group"  >
                            <input onChange={(e) => {checkPpnHandler(e.target.checked)}} type="checkbox" className="ppn-check" style={{width: "60px"}} />
                            <label>Ceklist PPN</label>
                            </div>
                            <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            decimalSeparator="."
                            value={valuePPnHandler()}
                            />
                            <h3>Denda</h3>
                            
                            <CurrencyInput
                                id="input-example"
                                name="input-name"
                                placeholder={0}
                                decimalSeparator="."
                                value={penalty}
                                onValueChange={(e) => {checkNum(e, (setPenalty))}}
                                /> */}

                            <h3>Tgl Jatuh Tempo:</h3>
                            <input disabled value={tgl_jatuhtempo} onChange={(e) => {setTglJatuhtempo(e.target.value)}} type = "date" />
                            
                            <h3>Grand Total: </h3>
                            <CurrencyInput
                            disabled    
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            defaultValue={0}
                            value={grandTotalHandler()}
                            />
                            {/* Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))+Number(penalty)-Number(discountPlus)+Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountInternetPercent/100)) */}
                            {/* Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountInternetPercent/100)) */}
                            {/* (custData.nominal*discount/100) */}
                            {/* +Number(addOn*qtyAddon) */}
                        {/* <input disabled type="number"  value={(parseInt(custData.nominal*qty+penalty-discount)).toLocaleString('IN')} /> */}
                        <div className = "form-button" > 
                            <button onClick={saveNewInvoice} className = "profile-save btn ">Simpan</button>
                            <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                        </div>
                        </>
                    }   

                        




                        
                    </div>
                    </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>
    )

}

export default NewInvoice;