import React, { useEffect, useState, useRef } from 'react';
import {
    FaArrowLeft,
    FaPrint
} from 'react-icons/fa'
import {useNavigate, useLocation} from 'react-router-dom'
import Swal from 'sweetalert2';
import {useReactToPrint} from 'react-to-print'

import { loadingHandler } from '../../components/Loading';
import Logo from '../../Assets/CHL-Logo.png'

import './DetailCetak.css'
import axios from 'axios';
import { baseUrl } from '../../Config/URL';
import angkaTerbilang from '@develoka/angka-terbilang-js';
import Sidebar from '../../components/Sidebar';
import { estateConfig } from '../../components/PageConfig';

const DetailCetak = () => {
    const [dataDetail, setDataDetail] = useState("")
    
    const [total, setTotal] = useState(0)
    
    const [yeariode_start, setYearPeriodeStart] = useState("")
    const [monthperiode_start, setMonthPeriodeStart] = useState("")
    const [monthperiode_end, setMonthPeriodeEnd] = useState("")
    const [yearperiode_end, setYearPeriodeEnd] = useState("")
    const [grandtotal_invoice, setGrandTotal] = useState(0)
    const [refrence, setRefrence] = useState("")
    const [dateFull, setDateFull] = useState(0)
    const [dateDue, setDateDue] = useState(0)

    const [iplInvoice, setIplInvoice] = useState(0)
    const [iplSubTotal, setIplSubTotal] = useState(0)
    const [total_ipl, setTotalIpl] = useState(0)
    const [diskonIpl, setDiskonIpl] = useState(0)
    const [diskonIplPersen, setDiskonIplPersen] = useState(0)

    const [internetInvoice, setInternetInvoice] = useState(0)
    const [internetSubTotal, setInternetSubTotal] = useState(0)
    const [total_internet, setTotalInternet] = useState(0)
    const [diskonInternet, setDiskonInternet] = useState(0)
    const [diskonInternetPersen, setDiskonInternetPersen] = useState(0)

    const [ppn, setPpn] = useState(0)
    const [ppnIpl, setPpnIpl] = useState(0)
    const [ppnIplNominal, setPpnIplNominal] = useState(0)
    const [ppnInternet, setPpnInternet] = useState(0)
    const [ppnInternetNominal, setPpnInternetNominal] = useState(0)

    const [denda, setDenda] = useState(0)

    const [startIpl, setStartIpl] = useState(0)
    const [endIpl, setEndIPl] = useState(0)
    const [startInternet, setStartInternet] = useState(0)
    const [endInternet, setEndInternet] = useState(0)


    


    const location = useLocation()
    const navigate = useNavigate()
    const componentRef = useRef()

    useEffect(() => {
        const {state} = location
        console.log(state);
        const dateFull = new Date()
        setDataDetail(state)
        
        // setYearPeriodeEnd(state.periode_end.split('-')[0])
        // setYearPeriodeStart(state.periode_start.split('-')[0])
        setGrandTotal(state.grandtotal_invoice)

        setIplSubTotal(Number(state.ipl_invoice*state.kuantitas_ipl))
        setDiskonIplPersen(Number(state.diskonipl_persen+state.diskonipl_nominaltopersen+state.promo_iplpersen))
        setDiskonIpl(Number(state.diskonipl_persentonominal+state.promo_iplnominal+state.diskonipl_nominal))
        setTotalIpl(state.total_ipl)
        setIplInvoice(state.ipl_invoice)

        setInternetSubTotal(Number(state.internet_invoice*state.kuantitas_internet))
        setInternetInvoice(state.internet_invoice)
        setDiskonInternetPersen(Number(state.diskoninternet_persen+state.diskoninternet_nominaltopersen+state.promo_internetpersen))
        setDiskonInternet(Number(state.diskoninternet_persentonominal+state.promo_internetnominal+state.diskoninternet_nominal))
        setTotalInternet(state.total_internet)

        setPpnIpl(state.ppn_ipl)
        setPpnIplNominal(state.ppn_iplnominal)
        setPpnInternet(state.ppn_internet)
        setPpnInternetNominal(state.ppn_internetnominal)
        setPpn(Number(state.ppn_iplnominal+state.ppn_internetnominal))

        setDenda(Number(state.denda_ipl+state.denda_internet))

        setStartIpl(state.startipl_invoice)
        setEndIPl(state.endipl_invoice)
        setStartInternet(state.startinternet_invoice)
        setEndInternet(state.endinternet_invoice)

        setDateDue(state.date_due.replace(/(\d+)(st|nd|rd|th)/, "$1"))
        setDateFull(state.date_full.replace(/(\d+)(st|nd|rd|th)/, "$1"))

        



        // console.log(state.ipl_invoice.toLocaleString('IN'));
        // console.log(state.periode_start.split('-')[1]);
        // bulanHandler(state.periode_start.split('-')[1], "start")
        // bulanHandler(state.periode_end.split('-')[1], "end" )

        generateRef(state)

    },[])

    const generateRef = (e) => {


      let id = 0
      let tglInit = new Date()
      let tgl = tglInit.getMonth(dataDetail.created_at)+1
      let tglRoman = "asd"
      let year = tglInit.getFullYear()

      if(e.no_invoice < 10){
        id = "00"+e.no_invoice
      }else if(e.no_invoice >= 10 && e.no_invoice < 100){
        id = "0"+e.no_invoice
      }else{
        id = e.no_invoice
      }


      switch (tgl) {
        case 1:
          tglRoman = "I"
          break;
        case 2:
            tglRoman = "II"
          break;
        case 3:
    tglRoman = "III";
          break;
        case 4:
    tglRoman = "IV";
          break;
        case 5:
tglRoman = "V";
          break;
        case 6:
  tglRoman = "VI";
          break;
        case 7:
  tglRoman = "VII";
          break;
        case 8:
        tglRoman = "VIII";
          break;
        case 9:
            tglRoman = "IX";
          break;
        case 10:
        tglRoman = "X";
          break;
        case 11:
          tglRoman = "XI";
          break;
        case 12:
          tglRoman = "XII";
          break;
    }



      // console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
      setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)

    }

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "INVOICE Mazenta_"+dataDetail.no_unit+"_"+dataDetail.date_full+"_"+dataDetail.nama,
        onAfterPrint: () => {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
        }
    })
    
    const nominalQty = dataDetail.ipl_invoice*dataDetail.kuantitas_ipl
    const addOnQty = dataDetail.internet_invoice*dataDetail.kuantitas_internet
    // const penalty = dataDetail.penalty_invoice
    // const diskon = dataDetail.potongan_invoice
    // const total = dataDetail.total_invoice

    const bulanHandler = (e, cond) => {

        if(cond === "start"){
            
            switch (e) {
                case "01":
                  setMonthPeriodeStart("Januari");
                  break;
                case "02":
                    setMonthPeriodeStart("Februari");
                  break;
                case "03":
                    setMonthPeriodeStart("Maret");
                  break;
                case "04":
                    setMonthPeriodeStart("April");
                  break;
                case "05":
                    setMonthPeriodeStart("Mei");
                  break;
                case "06":
                    setMonthPeriodeStart("Juni");
                  break;
                case "07":
                    setMonthPeriodeStart("Juli");
                  break;
                case "08":
                    setMonthPeriodeStart("Agustus");
                  break;
                case "09":
                    setMonthPeriodeStart("September");
                  break;
                case "10":
                    setMonthPeriodeStart("Oktober");
                  break;
                case "11":
                    setMonthPeriodeStart("November");
                  break;
                case "12":
                    setMonthPeriodeStart("Desember");
                  break;
            }

        }else if(cond === "end"){
            switch (e) {
                case "01":
                  setMonthPeriodeEnd("Januari");
                  break;
                case "02":
                    setMonthPeriodeEnd("Februari");
                  break;
                case "03":
                    setMonthPeriodeEnd("Maret");
                  break;
                case "04":
                    setMonthPeriodeEnd("April");
                  break;
                case "05":
                    setMonthPeriodeEnd("Mei");
                  break;
                case "06":
                    setMonthPeriodeEnd("Juni");
                  break;
                case "07":
                    setMonthPeriodeEnd("Juli");
                  break;
                case "08":
                    setMonthPeriodeEnd("Agustus");
                  break;
                case "09":
                    setMonthPeriodeEnd("September");
                  break;
                case "10":
                    setMonthPeriodeEnd("Oktober");
                  break;
                case "11":
                    setMonthPeriodeEnd("November");
                  break;
                case "12":
                    setMonthPeriodeEnd("Desember");
                  break;
            }
        }

        

    }
    


    return (
      <Sidebar menuItems={estateConfig}>
        <main id='newcustomer-container' >

            
            <form>
            <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/cetakipl")}} />
            
            <div className='cetak-button-container' onClick={printHandler} >
                <FaPrint className='cetak-button' size={30} onClick={printHandler}/>
                <text>Print</text>
            </div>
            {/* <text className='cetak-button' onClick={printHandler} >Print</text> */}
            <section ref={componentRef} className='cetak' id='cetak-container'>

                <h1 className='invoice-title'>Invoice</h1>
                
                <div className='cetak-head' >

                    <div className='cetak-logo' >
                        <img src={Logo} alt='logo-chl' />
                    </div>

                    <div className='cetak-refrensi' >
                    <table>
                            <tr>
                                <td>Referensi</td>
                                <td>: {refrence}</td>
                            </tr>
                            <tr>
                                <td>Tanggal</td>
                                <td>: {dateFull}</td>
                            </tr>
                            <tr>
                                <td>Jatuh Tempo</td>
                                <td>: {dateDue}</td>
                            </tr>
                        </table>
                            {/* <p>Referensi : {refrence} </p>
                            <p>Tanggal   : <text>{dataDetail.date_full}</text> </p>
                            <p>Jatuh Tempo : <text>{dataDetail.date_due}</text></p> */}
                    </div>

                </div>

                <div className='info-customer' >

                <div className='cetak-infoperusahaan' >
                            <h3>Info Perusahaan:</h3>
                            <h4>Mazenta Residence</h4>
                            <p>Management Mazenta Residence</p>
                            <p>Jl. Bakti Karya Raya, Sawah Baru, Kec. Ciputat, Tangerang , Banten 15413</p>
                            <p>Telp: +62 21 2222 0080</p>
                            <p>Fax : +62 21 2222 0081</p>
                </div>

                    
                    <div className='detail-info' >
                    <h3>Tagihan Untuk:</h3>
                        <h4>Bapak/Ibu. {dataDetail.nama_customer}</h4>
                        <table>
                            <tr>
                                <td>Unit</td>
                                <td>: {dataDetail.no_unit_invoice}</td>
                            </tr>
                            <tr>
                                <td>Telp</td>
                                <td>: {dataDetail.no_telp_invoice}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>: {dataDetail.email_invoice}</td>
                            </tr>
                        </table>

                        {/* <p>Kluster: Mazenta Bintaro </p>
                        <p>Unit: {dataDetail.no_unit}</p>
                        <p>Telp: {dataDetail.no_hp1}</p>
                        <p>Email: {dataDetail.alamat_email}</p> */}
                    </div>

                </div>



                <div className='paragraph' >

                    <p className='space' >Kepada Yth, Bapak/Ibu</p>
                    <p>Terima kasih kami ucapkan kepada Bapak/Ibu sebagai Penghuni dari Perumahan Mazenta Residence yang berlokasi dikawasan
                    yang telah memilih Mazenta Residence sebagai salah satu tempat tinggal.
Bersama ini kami kirimkan Invoice tagihan dengan rincian sebagai berikut :</p>

                </div>
                
                <div className='header-desc' >

                    <text className='no'>No.</text>
                    <text className='keterangan'>Keterangan</text>
                    <text>Total</text>
                    <text className='persen'>% Diskon</text>
                    <text>Total Diskon</text>
                    <text>Total Tagihan</text>

                </div>

                <div className='body-desc inv' >

                {dataDetail.kuantitas_ipl*dataDetail.total_ipl > 0 
                      ? 
                      <>
                        <text className='no'>1</text>
                    <text className='keterangan'><p>Iuran Pemeliharaan Lingkungan (IPL)</p>
                    <h5>Rp {iplInvoice.toLocaleString('IN')} x {dataDetail.kuantitas_ipl} Bulan </h5>
                    <h5 className='periode-desc'>
                      {endIpl === null
                        ? <>Periode IPL {startIpl} </>
                        : <>Periode IPL dari {startIpl} s/d {endIpl} </>
                      }
                    </h5>
                    </text>
                    <text><p>Rp</p><p>{iplSubTotal.toLocaleString('IN')}</p></text>
                    <text className='persen'>
                      {diskonIplPersen > 0 && <>{diskonIplPersen}%</>}
                     </text>
                    <text>{diskonIpl > 0 && <><p>Rp</p><p>{diskonIpl.toLocaleString("IN")}</p></>}</text>
                    <text><p>Rp</p><p>{total_ipl.toLocaleString('IN')}</p></text>
                      </>
                      : <></>
                    }

                    

                </div>

                <div className='body-desc inv' >
                    {dataDetail.kuantitas_internet*dataDetail.total_internet > 0 
                      ? 
                      <>
                        <text className='no'>
                          {
                            dataDetail.kuantitas_ipl*dataDetail.total_ipl > 0
                            ? <>2</>
                            :<>1</>
                          }
                        </text>
                        <text className='keterangan'><p>Internet Speed {dataDetail.speed_internet} Mbps</p>
                                <h5>Rp {internetInvoice.toLocaleString('IN')} x {dataDetail.kuantitas_internet} Bulan </h5>
                                <h5 className='periode-desc'>
                                {endInternet === null
                                  ? <>Periode Internet {startInternet} </>
                                  : <>Periode Internet dari {startInternet} s/d {endInternet} </>
                                }
                                </h5>
                                </text>
                        <text><p>Rp</p><p>{internetSubTotal.toLocaleString('IN')}</p></text>
                        <text className='persen'>
                        {diskonInternetPersen > 0 && <>{diskonInternetPersen.toLocaleString("IN")}%</>}
                        </text>
                        <text>{diskonInternet > 0 && <><p>Rp</p><p>{diskonInternet.toLocaleString('IN')}</p></>}</text>
                        <text><p>Rp</p><p>{total_internet.toLocaleString('IN')}</p></text>
                      </>
                      : <>
                      
                      <>
                        <text className='no'>
                          {
                            dataDetail.kuantitas_ipl*dataDetail.total_ipl > 0
                            ? <></>
                            :<>1</>
                          }
                        </text>
                        <text className='keterangan'><p></p>
                                <h5></h5>
                                <h5 className='periode-desc'>
                                {/* {endIpl === null
                                  ? <> </>
                                  : <>Periode IPL dari {startInternet} s/d {endInternet} </>
                                } */}
                                </h5>
                                </text>
                        <text></text>
                        <text className='persen'>
                        
                        </text>
                        <text></text>
                        <text></text>
                      </>
                        </>
                    }
                    

                </div>

                {
                  dataDetail.kuantitas_ipl*dataDetail.total_ipl <= 0 
                  ?
                  <div className='body-desc inv' >
                    <text className='no'>
                          <></>
                        </text>
                        <text className='keterangan'><p></p>
                                <h5></h5>
                                <h5 className='periode-desc'>
                                {/* {endIpl === null
                                  ? <> </>
                                  : <>Periode IPL dari {startInternet} s/d {endInternet} </>
                                } */}
                                </h5>
                                </text>
                        <text></text>
                        <text className='persen'>
                        
                        </text>
                        <text></text>
                        <text></text>

                </div>
                :<></>
                }


               

                <div className='total' >

                    {/* <div className='null' >
                        
                    </div> */}
                   
                    
                    <div className='desc-total' >
                        
                        <h3>Sub Total :</h3>
                        <h3>{ppn > 0 && <>PPN :</>}</h3>
                        <h3>{denda > 0 && <>Denda :</>}</h3>
                        <h3 className = 'grand' >GRAND TOTAL</h3>
                    </div>

                    <div className='total-nominal' >
                        
                        <h3>Rp<span>{(total_ipl+total_internet).toLocaleString('IN')}</span></h3>
                        <h3>Rp<span>{ppn > 0 && <>{ppn.toLocaleString("IN")}</>}</span></h3>
                        <h3>Rp<span>{denda > 0 && <>{denda.toLocaleString("IN")}</>}</span></h3>
                        <h3 className='grand' >Rp<span>{grandtotal_invoice.toLocaleString('IN')}</span></h3>
                    </div>

                </div>

                

                <div className='note-invoice' >
                <div className='spelled' >
                      <p>Terbilang   :   {angkaTerbilang(grandtotal_invoice).split(' ').map(item => {
                            return item.charAt(0).toUpperCase() + item.substring(1)
                        }).join(' ')} rupiah</p>
                </div>
                    <p>Untuk administrasi, kami mohon agar melakukan pembayaran sesuai Invoice yang telah kami sampaikan melalui:</p>
                    <h4>Rekening PT. GRIYA GARDENIA INDAH</h4>

                    <div className='bank-container'>
                        <div className='bank-detail'>
                            <p>Bank</p>
                            <p>No. Virtual Account</p>
                            <p>Cabang</p>
                        </div>
                        <div className='bank-fill' >
                            <p>: Bank Mandiri</p>
                            <p>: {dataDetail.no_va_invoice}</p>
                            <p>: KCU KISAMAUN</p>
                        </div>
                    </div>
                    
                    
                    
                </div>

                <div className='sign' >
                    <ul>
                        <li>Tagihan IPL = Luas (M2) x Rp 7.000</li>
                        <li>Denda Keterlambatan akan dikenakan setelah Tanggal Jatuh Tempo</li>
                        <li>Pembayaran melalui Virtual Account yang tertera pada Invoice.</li>
                        <li>Mohon Bukti Pembayaran Dikirimkan Via email tenant.relation@mazenta.id atau melalui No WhatShapp tenant relation kami di +(62) 812 9181 0348</li>
                        <li>Kwitansi pembayaran dapat diambil di kantor Management Mazenta Residence.</li>
                        <li>Invoice ini di cetak oleh system dan tidak membutuhkan tanda tangan</li>
                    </ul>
                </div>

            </section>
            
        </form>

            
        </main>
        </Sidebar>
    );
};

export default DetailCetak ;