import React, { useEffect, useState } from 'react';
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate, useLocation} from 'react-router-dom'
import Swal from 'sweetalert2';
import { loadingHandler } from '../../components/Loading';

import axios from 'axios';
import { baseUrl } from '../../Config/URL';
import Sidebar from '../../components/Sidebar';
import { salesConfig } from '../../components/PageConfig';
import { dateFormater2 } from '../../components/Reuse';

const EditDetailCustomer = () => {
    //set identitas
    const [noReservasi, setNoReservasi] = useState("")
    const [noCust, setNoCust] = useState("")
    const [idProject, setIdProject] = useState("")
    const [idDataSource, setIdDataSource] = useState("")
    const [idBuyType, setIdBuyType] = useState("")
    const [Kewarganegaraan, setKewarganegaraan] = useState("")
    const [nama, setNama] = useState("")
    //set kartu identitas
    const [noidentitas, setNoIdentitas] = useState("")
    const [alamat, setAlamat] = useState("")
    const [rtrw, setRtrw] = useState("")
    const [kelurahan, setKelurahan] = useState("")
    const [kecamatan, setKecamatan] = useState("")
    const [kota, setKota] = useState("")
    const [kodepos, setKodepos] = useState("")
    //set data pribadi
    const [agama, setAgama] = useState("")
    const [statusNikah, setStatusNikah] = useState("")
    const [tempatLahir, setTempatLahir] = useState("")
    const [tglLahir, setTglLahir] = useState(null)
    //set data npwp
    const [namaNpwp, setNamaNpwp] = useState("")
    const [npwp, setNpwp] = useState("")
    const [alamatNpwp, setAlamatNpwp] = useState("")
    const [rtrwNpwp, setRtrwtNpwp] = useState("")
    const [kelurahanNpwp, setKelurahanNpwp] = useState("")
    const [kecamatanNpwp, setKecamatanNpwp] = useState("")
    const [kotaNpwp, setKotaNpwp] = useState("")
    //set data kontak
    const [noTlpn, setNoTlpn] = useState("")
    const [noHp1, setNoHp1] = useState("")
    const [noHp2, setNoHp2] = useState("")
    const [noFax, setNoFax] = useState("")
    const [email, setEmail] = useState("")
    //set data alamat surat menyurat
    const [alamatSurat, setAlamatSurat] = useState("")
    const [rtrwSurat, setRtrwSurat] = useState("")
    const [kelurahanSurat, setKelurahanSurat] = useState("")
    const [kecamatannSurat, setKecamatanSurat] = useState("")
    const [kotaSurat, setKotaSurat] = useState("")
    //set data pekerjaan
    const [pekerjaan, setPekerjaan] = useState("")
    const [noTlpnKantor, setNoTlpnKantor] = useState("")
    const [alamatKantor, setAlamatKantor] = useState("")
    const [rtrwKantor, setRtrwKantor] = useState("")
    const [kelurahanKantor, setKelurahanKantor] = useState("")
    const [kecamatanKantor, setKecamatanKantor] = useState("")
    const [kotaKantor, setKotaKantor] = useState("")
    //set data rekan/keluarga
    const [namaRekan, setNamaRekan] = useState("")
    const [hubungan, setHubungan] = useState("")
    const [noTlpnRekan, setNoTlpnRekan] = useState("")
    const [emailRekan, setEmailRekan] = useState("")
    
    const [isLoading, setIsLoading] = useState(false)

    const [projectGet, setIdProjectGet] = useState([])
    const [typeCusGet, setTypeCustGet] = useState([])
    const [sumberDataGet, setSumberDataGet] = useState([])

    
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        loadingHandler(isLoading)
    })

    useEffect(() => {
        getDataProject()
        getSumberDataGet()
        getTypeCust()
        getDataCustomer()
    },[])


    const getDataCustomer = () => {
        const {state} = location
        console.log(state);
        axios.post(baseUrl+"getallcustomerdata",{
            id: state
        })
        .then(res => {
            console.log(res.data);
        const data = res.data[0]    

        setNoReservasi(data.no_reservasi || null )
        setNoCust(data.no_customer || null )
        setNama(data.nama || null )
        setIdProject(data.id_project || null )
        setIdDataSource(data.id_sumberdata || null )
        setIdBuyType(data.id_typecust || null )
        setKewarganegaraan(data.kewarganegaraan || null )

        setNoIdentitas(data.no_identitas || null )
        setAlamat(data.alamat || null )
        setRtrw(data.rt_rw || null )
        setKelurahan(data.kelurahan || null )
        setKecamatan(data.kecamatan || null )
        setKota(data.kota || null )
        setKodepos(data.kode_pos || null ) 

        setAgama(data.agama || null )
        setStatusNikah(data.status_nikah || null )
        setTempatLahir(data.tempat_lahir || null )
        setTglLahir(data.tanggal_lahir !== null && dateFormater2(data.tanggal_lahir) || null )

        setNamaNpwp(data.nama_npwp || null )
        setNpwp(data.npwp || null )
        setAlamatNpwp(data.alamat_npwp || null )
        setRtrwtNpwp(data.rtrw_npwp || null )
        setKelurahanNpwp(data.kelurahan_npwp || null )
        setKecamatanNpwp(data.kecamatan_npwp || null )
        setKotaNpwp(data.kota_npwp || null )

        setNoTlpn(data.no_telepon || null )
        setNoHp1(data.no_hp1 || null )
        setNoHp2(data.no_hp2 || null )
        setNoFax(data.no_fax || null )
        setEmail(data.alamat_email || null )

        setAlamatSurat(data.alamat_surat || null )
        setRtrwSurat(data.rtrw_surat || null )
        setKelurahanSurat(data.kelurahan_surat || null )
        setKecamatanSurat(data.kecamatan_surat || null )
        setKotaSurat(data.kota_surat || null )

        setPekerjaan(data.pekerjaan || null )
        setNoTlpnKantor(data.no_telpkantor || null )
        setAlamatKantor(data.alamat_kantor || null )
        setRtrwKantor(data.rtrw_kantor || null )
        setKelurahanKantor(data.kelurahan_kantor || null )
        setKecamatanKantor(data.kecamatan_kantor || null )
        setKotaKantor(data.kota_kantor || null )

        setNamaRekan(data.nama_rekan || null )
        setHubungan(data.hubungan || null )
        setNoTlpnRekan(data.telp_rekan || null )
        setEmailRekan(data.email_rekan || null )
        

        })
    }


    const postData = (e) => {
        e.preventDefault()
        const {state} = location
        setIsLoading(true)
        axios.patch(baseUrl+'editcustomer',{
                        no_reservasi : noReservasi,
                        no_customer : noCust, 
                        nama : nama,
                        kewarganegaraan : Kewarganegaraan,
                        id_project : idProject,
                        id_sumberdata : idDataSource,
                        id_typecust : idBuyType,
                        no_identitas : noidentitas,
                        alamat : alamat,
                        rt_rw : rtrw,
                        kelurahan : kelurahan,
                        kecamatan : kecamatan,
                        kota : kota,
                        kode_pos : kodepos,
                        agama : agama,
                        status_nikah : statusNikah,
                        tempat_lahir : tempatLahir,
                        tanggal_lahir : tglLahir,
                        npwp : npwp,
                        nama_npwp : namaNpwp,
                        alamat_npwp : alamatNpwp,
                        rtrw_npwp : rtrwNpwp,
                        kelurahan_npwp : kelurahanNpwp,
                        kecamatan_npwp : kecamatanNpwp,
                        kota_npwp : kotaNpwp,
                        no_telepon : noTlpn,
                        no_hp1 : noHp1,
                        no_hp2 : noHp2,
                        no_fax : noFax,
                        alamat_email : email,
                        alamat_surat : alamatSurat,
                        rtrw_surat : rtrwSurat,
                        kelurahan_surat : kelurahanSurat,
                        kecamatan_surat : kecamatannSurat,
                        kota_surat : kotaSurat,
                        pekerjaan : pekerjaan,
                        no_telpkantor : noTlpnKantor,
                        alamat_kantor : alamatKantor,
                        rtrw_kantor : rtrwKantor,
                        kelurahan_kantor : kelurahanKantor,
                        kecamatan_kantor : kecamatanKantor,
                        kota_kantor : kotaKantor,
                        nama_rekan : namaRekan,
                        hubungan : hubungan,
                        email_rekan : emailRekan,
                        telp_rekan : noTlpnRekan,
                        id: state

        }).then(res => {
            console.log(res);
            setIsLoading(false)
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/editcustomer")
                }
              })
        }).catch(err => {
            setIsLoading(false)
            console.log(err.response.data.msg);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })
    }

    const npwpCheckHandler = (e) => {
        if(e){
            setNamaNpwp(nama)
            setNpwp(noidentitas)
            setAlamatNpwp(alamat)
            setRtrwtNpwp(rtrw)
            setKelurahanNpwp(kelurahan)
            setKecamatanNpwp(kecamatan)
            setKotaNpwp(kota)
        }else{
            setNamaNpwp("")
            setNpwp("")
            setAlamatNpwp("")
            setRtrwtNpwp("")
            setKelurahanNpwp("")
            setKecamatanNpwp("")
            setKotaNpwp("")
        }
    }


    const suratCheckHandler = (e) => {
        if(e){
            setAlamatSurat(alamat)
            setRtrwSurat(rtrw)
            setKelurahanSurat(kelurahan)
            setKecamatanSurat(kecamatan)
            setKotaSurat(kota)
        }else{
            setAlamatSurat("")
            setRtrwSurat("")
            setKelurahanSurat("")
            setKecamatanSurat("")
            setKotaSurat("")
        }
    }

    const getDataProject = () => {
        axios.get(baseUrl+"getallproject")
        .then(res => {
            console.log("getProject_"+res.data);
            setIdProjectGet(res.data)
        }).catch(err => {
            console.log(err);
        })
    }



    const getSumberDataGet = () => {
        axios.get(baseUrl+"getallsourcedata")
        .then(res => {
            console.log("getDumberData"+res.data);
            setSumberDataGet(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const getTypeCust = () => {
        axios.get(baseUrl+"getalltypecust")
        .then(res => {
            console.log("getTypeCust"+res.data);
            setTypeCustGet(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    

    // console.log(isLoading);

    //console.log("Hubungan" + " " + typeof(hubungan)+hubungan );

    return (
        <Sidebar menuItems={salesConfig} >
        <main id='newcustomer-container' className='pendaftaran' >

            
            <form>
            <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/editcustomer")}} />
            
            <h1>Perubahan Data Customer</h1>
            <h3>Identitas</h3>
            <div class="row">
                <div class="column">
                    <label for="name">No. Reservasi/ No. NUP: </label>
                    <input value={noReservasi} onChange={(e)=>{setNoReservasi(e.target.value)}} type="text" id="no-nup" placeholder="No. Reservasi/ No. NUP"/>
                </div>
                <div class="column">
                    <label for="unitcode">No. Customer: </label>
                    <input value={noCust} onChange={(e)=>{setNoCust(e.target.value)}} type="text" id="no-cusomer" placeholder="No. Customer"/>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="project">Project: </label>
                    <select value={idProject} onChange={(e)=>{setIdProject(e.target.value)}} id='project' placeholder='Project' >
                        <option value="" >--Select Project--</option>
                        {/* <option value={1} >Mazenta</option> */}
                        {projectGet.map((item, index) => {
                            return(
                                <>
                                
                                    <option value={item.id} >{item.project_name}</option>
                                
                                </>
                            )
                        })}
                    </select>
                    
                </div>
                <div class="column">
                    <label for="sumber-data">Sumber Data: </label>
                    <select value={idDataSource} onChange={(e)=>{setIdDataSource(e.target.value)}} id='sumber-data' placeholder='Sumber Data' >
                        <option>--Sumber Data--</option>
                        {sumberDataGet.map((item, index) => {
                            return(
                                <>
                                
                                    <option value={item.id} >{item.sourcedata_name}</option>
                                
                                </>
                            )
                        })}
                    </select>
                    
                </div>
            </div>
            <div class="row">
                
                <div class="column">
                    <label for="tipe-buyer">Tipe: </label>
                    <select value={idBuyType} onChange={(e)=>{setIdBuyType(e.target.value)}} id='tipe-buyer' placeholder='Tipe' >
                        <option>--Select Tipe--</option>
                        {typeCusGet.map((item, index) => {
                            return(
                                <>
                                
                                    <option value={item.id} >{item.typecust_name}</option>
                                
                                </>
                            )
                        })}
                    </select>
                    
                </div>

                <div class="column">
                    <label for="warga-negara">Kewarganegaraan: </label>
                    <select value={Kewarganegaraan} onChange={(e)=>{setKewarganegaraan(e.target.value)}} id='warga-negara' placeholder='Kewarganegaraan' >
                        <option value="" >--Kewarganegaraan--</option>
                        <option value={"WNI"}>WNI</option>
                        <option value={"WNA"}>WNA</option>
                    </select>
                    
                </div>
            </div>

            <div class="row">
                
                <div class="column">
                    <label for="nama">Nama: </label>
                    <input type="text" id="nama" placeholder="Nama" value={nama} onChange={(e)=>{setNama(e.target.value)}} />
                </div>
                
            </div>

            <h3>Kartu Identitas</h3>

            <div class="row">
                <div class="column">
                    <label for="no-identitas">No. Identitas: </label>
                    <input value={noidentitas} onChange={(e)=>{setNoIdentitas(e.target.value)}} type="number" id="no-identitas" placeholder="No. Identitas"/>
                </div>
                <div class="column">
                    <label for="alamat">Alamat: </label>
                    <input value={alamat} onChange={(e)=>{setAlamat(e.target.value)}} type="text" id="alamat" placeholder="Alamat"/>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <label for="rt-rw">RT/RW: </label>
                    <input value={rtrw} onChange={(e)=>{setRtrw(e.target.value)}} type="text" id="rt-rw" placeholder="RT/RW"/>
                </div>
                <div class="column">
                    <label for="kelurahan">Kelurahan: </label>
                    <input value={kelurahan} onChange={(e)=>{setKelurahan(e.target.value)}} type="text" id="kelurahan" placeholder="Kelurahan"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kecamatan">Kecamatan: </label>
                    <input value={kecamatan} onChange={(e)=>{setKecamatan(e.target.value)}} type="text" id="kecamatan" placeholder="Kecamatan"/>
                </div>
                <div class="column">
                    <label for="kota-kabupaten">Kotamadya/Kabupaten: </label>
                    <input value={kota} onChange={(e)=>{setKota(e.target.value)}} type="text" id="kota-kabupaten" placeholder="Kotamadya/Kabupaten"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kode-pos">Kode Pos: </label>
                    <input value={kodepos} onChange={(e)=>{setKodepos(e.target.value)}} type="number" id="kode-pos" placeholder="Kode Pos"/>
                </div>
            </div>
            
            <h3>Data Pribadi</h3>
            
            <div class="row">
                
                <div class="column">
                    <label for="agama">Agama: </label>
                    <select value={agama} onChange={(e)=>{setAgama(e.target.value)}} id='agama' placeholder='Agama' >
                    <option value={""} >--Select Agama--</option>
                        <option value={"Islam"}>Islam</option>
                        <option value={"Kristen"}>Kristen</option>
                        <option value={"Katholik"}>Katolik</option>
                        <option value={"Budha"}>Budha</option>
                        <option value={"Hindu"}>Hindu</option>
                        <option value={"Konghucu"}>Konghucu</option>
                        <option value={"Lainnya"}>Lainnya</option>
                    </select>
                    
                </div>

                <div class="column">
                    <label for="status-pernikahan">Status:</label>
                    <select value={statusNikah} onChange={(e)=>{setStatusNikah(e.target.value)}}  id='status-pernikahan' placeholder='Status' >
                        <option value={""}>--Status--</option>
                        <option value={"Menikah"}>Menikah</option>
                        <option value={"Belum Menikah"}>Belum Menikah</option>
                        <option value={"Cerai"}>Cerai</option>
                        <option value={"Lain Lain"}>Lain - Lain</option>
                    </select>
                    
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <label for="tempat-lahir">Tempat Lahir: </label>
                    <input value={tempatLahir} onChange={(e)=>{setTempatLahir(e.target.value)}} type="text" id="tempat-lahir" placeholder="Tempat Lahir"/>
                </div>
                <div class="column">
                    <label for="tanggal-lahir">Tanggal Lahir:</label>
                    <input value={tglLahir} onChange={(e)=>{setTglLahir(e.target.value)}} type="date" id="tanggal-lahir" placeholder="Tanggal Lahir"/>
                </div>
            </div>

            <h3>Data NPWP</h3>
            
            <div class="row">
                <div class="column" style={{flexDirection: "row", alignItems: "center", gap: "10px"}} >
                    
                    <input type = "checkbox" onChange={(e) => {npwpCheckHandler(e.target.checked)}}  style={{width: "20px"}} />
                    <label for="check-same">Sama Dengan KTP </label>
                </div>
                
            </div>

            <div class="row">
                <div class="column">
                    <label for="nama-npwp">Nama NPWP: </label>
                    <input value={namaNpwp} onChange={(e) => {setNamaNpwp(e.target.value)}} type="text" id="nama-npwp" placeholder="Nama NPWP" />
                </div>
                <div class="column">
                    <label for="npwp">NPWP: </label>
                    <input value={npwp} onChange={(e) => {setNpwp(e.target.value)}} type="number" id="npwp" placeholder="NPWP"/>
                </div>
                
            </div>

            <div class="row">
                <div class="column">
                    <label for="alamat-npwp">Alamat NPWP: </label>
                    <input value={alamatNpwp} onChange={(e) => {setAlamatNpwp(e.target.value)}} type="text" id="alamat-npwp" placeholder="Alamat NPWP"/>
                </div>
                <div class="column">
                    <label for="rt-rw-npwp">RT/RW NPWP: </label>
                    <input value={rtrwNpwp} onChange={(e) => {setRtrwtNpwp(e.target.value)}} type="text" id="rt-rw-npwp" placeholder="RT/RW"/>
                </div>
                
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kelurahan-npwp">Kelurahan:</label>
                    <input value={kelurahanNpwp} onChange={(e) => {setKelurahanNpwp(e.target.value)}} type="text" id="kelurahan-npwp" placeholder="Kelurahan"/>
                </div>
                <div class="column">
                    <label for="kecamatan-npwp">Kecamatan: </label>
                    <input value={kecamatanNpwp} onChange={(e) => {setKecamatanNpwp(e.target.value)}} type="text" id="kecamatan-npwp" placeholder="Kecamatan"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kota-kabupaten-npwp">Kotamadya/Kabupaten:</label>
                    <input value={kotaNpwp} onChange={(e) => {setKotaNpwp(e.target.value)}} type="text" id="kota-kabupaten-npwp" placeholder="Kotamadya/Kabupaten"/>
                </div>
            </div>

            <h3>Kontak</h3>


            <div class="row">
                <div class="column">
                    <label for="no-telpon">No. Telepon: </label>
                    <input value={noTlpn} onChange={(e)=>{setNoTlpn(e.target.value)}} type="number" id="no-telpon" placeholder="No. Telepon"/>
                </div>
                <div class="column">
                    <label for="no-hp1">No. HP 1: </label>
                    <input value={noHp1} onChange={(e)=>{setNoHp1(e.target.value)}} type="number" id="no-hp1" placeholder="No. HP 1"/>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <label for="no-hp2">No. HP 2: </label>
                    <input value={noHp2} onChange={(e)=>{setNoHp2(e.target.value)}} type="number" id="no-hp2" placeholder="No. HP 2"/>
                </div>
                <div class="column">
                    <label for="no-fax">No. Fax: </label>
                    <input value={noFax} onChange={(e)=>{setNoFax(e.target.value)}} type="number" id="no-fax" placeholder="No. Fax"/>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <label for="email">Alamat Email: </label>
                    <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="email" id="email" placeholder="Alamat Email"/>
                </div>
            </div>

            <h3>Alamat Surat Menyurat</h3>

            <div class="row">
                <div class="column" style={{flexDirection: "row", alignItems: "center", gap: "10px"}} >
                    
                    <input onChange={(e) => {suratCheckHandler(e.target.checked)}} type = "checkbox"  style={{width: "20px"}} />
                    <label for="check-same">Sama Dengan KTP </label>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <label for="alamat-surat">Alamat Surat Menyurat: </label>
                    <input value={alamatSurat} onChange={(e) => {setAlamatSurat(e.target.value)}} type="text" id="alamat-surat" placeholder="Alamat Surat Menyurat"/>
                </div>
                <div class="column">
                    <label for="rt-rw-surat">RT/RW: </label>
                    <input value={rtrwSurat} onChange={(e) => {setRtrwSurat(e.target.value)}} type="text" id="rt-rw-surat" placeholder="RT/RW"/>
                </div>
                
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kelurahan-surat">Kelurahan:</label>
                    <input value={kelurahanSurat} onChange={(e) => {setKelurahanSurat(e.target.value)}} type="text" id="kelurahan-surat" placeholder="Kelurahan"/>
                </div>
                <div class="column">
                    <label for="kecamatan-surat">Kecamatan: </label>
                    <input value={kecamatannSurat} onChange={(e) => {setKecamatanSurat(e.target.value)}} type="text" id="kecamatan-surat" placeholder="Kecamatan"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kota-kabupaten-surat">Kotamadya/Kabupaten:</label>
                    <input value={kotaSurat} onChange={(e) => {setKotaSurat(e.target.value)}} type="text" id="kota-kabupaten-surat" placeholder="Kotamadya/Kabupaten"/>
                </div>
            </div>

            <h3>Data Pekerjaan</h3>


            <div class="row">
                <div class="column">
                    <label for="pekerjaan">Pekerjaan: </label>
                    <input value={pekerjaan} onChange={(e)=>{setPekerjaan(e.target.value)}} type="text" id="Pekerjaan" placeholder="Pekerjaan"/>
                </div>
                <div class="column">
                    <label for="no-telepon-kantor">No. Telepon Kantor: </label>
                    <input value={noTlpnKantor} onChange={(e)=>{setNoTlpnKantor(e.target.value)}} type="text" id="no-telepon-kantor" placeholder="No. Telepon Kantor"/>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <label for="alamat-kantor">Alamat Kantor: </label>
                    <input value={alamatKantor} onChange={(e)=>{setAlamatKantor(e.target.value)}} type="text" id="alamat-kantor" placeholder="Alamat Surat Kantor"/>
                </div>
                <div class="column">
                    <label for="rt-rw-kantor">RT/RW: </label>
                    <input value={rtrwKantor} onChange={(e)=>{setRtrwKantor(e.target.value)}} type="text" id="rt-rw-kantor" placeholder="RT/RW"/>
                </div>
                
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kelurahan-kantor">Kelurahan:</label>
                    <input value={kelurahanKantor} onChange={(e)=>{setKelurahanKantor(e.target.value)}} type="text" id="kelurahan-kantor" placeholder="Kelurahan"/>
                </div>
                <div class="column">
                    <label for="kecamatan-kantor">Kecamatan: </label>
                    <input value={kecamatanKantor} onChange={(e)=>{setKecamatanKantor(e.target.value)}} type="text" id="kecamatan-kantor" placeholder="Kecamatan"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kota-kabupaten-kantor">Kotamadya/Kabupaten:</label>
                    <input value={kotaKantor} onChange={(e)=>{setKotaKantor(e.target.value)}} type="text" id="kota-kabupaten-kantor" placeholder="Kotamadya/Kabupaten"/>
                </div>
            </div>

            <h3>Orang Yang Dapat Dihubungi</h3>

            <div class="row">
                <div class="column">
                    <label for="nama-kerabat">Nama: </label>
                    <input value={namaRekan} onChange={(e)=>{setNamaRekan(e.target.value)}} type="text" id="nama-kerabat" placeholder="Nama"/>
                </div>
                <div class="column">
                    <label for="hubungan">Hubungan:</label>
                    <select value={hubungan} onChange={(e)=>{setHubungan(e.target.value)}} id='hubungan' placeholder='Hubungan' >
                        <option value={""}>--Hubungan--</option>
                        <option>Orang Tua</option>
                        <option>Suami</option>
                        <option>Istri</option>
                        <option>Anak</option>
                        <option>Saudara</option>
                        <option>Teman</option>
                        <option>Lainnya</option>
                    </select>
                    
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <label for="no-telepon-kerabat">No. Telepon: </label>
                    <input value={noTlpnRekan} onChange={(e)=>{setNoTlpnRekan(e.target.value)}} type="text" id="no-telepon-kerabat" placeholder="No. Telepon"/>
                </div>
                <div class="column">
                    <label for="email-kerabat">Alamat Email: </label>
                    <input value={emailRekan} onChange={(e)=>{setEmailRekan(e.target.value)}}t type="email" id="email-kerabat" placeholder="Alamat Email"/>
                </div>
            </div>


            
            
            <button onClick={(e) => {postData(e)}} >Submit</button>
        </form>

            
        </main>
        </Sidebar>
    );
};

export default EditDetailCustomer;