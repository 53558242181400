import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    FaArrowLeft
} from 'react-icons/fa'
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import {loadingHandler} from "../../components/Loading"
import { Construct } from "../../components/Constuct";
import CurrencyInput from "react-currency-input-field";
import Sidebar from "../../components/Sidebar";
import { estateConfig, settingConfig } from "../../components/PageConfig";



const SettingRate = () => {
    // const [allRole, setAllRole] = useState([])
    //IPL RATE
    const [dataIplRate, setDataIplRate] = useState({})
    const [tarifIpl, setTarifIpl] = useState(0)
    const [ipl_rate, setIplRate] = useState(0)
    const [periodestart_iplrate, setPeriodeStartIplRate] = useState("")
    const [periodeend_iplrate, setPeriodeEndIplRate] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    //INTERNET RATE
    const [dataInternetRate, setDataInternetRate] = useState([])
    const [id_internet, setIdInternet] = useState("")
    const [tarif_internet, setTarifInternet] = useState(0)
    const [periodestart_internetrate, setPeriodeStartInternet] = useState("")
    const [periodeend_internetrate, setPeriodeEndInternet] = useState("")
    const [speed_internet, setSpeedInternet] = useState("")



    const location = useLocation()
    const navigate = useNavigate()
    const {state} = location

    useEffect(() => {
        getIplRate()
        getInternetRate()
    },[])

    useEffect(() => {
        loadingHandler(isLoading)
    })

    
    const getIplRate = () => {
        axios.get(baseUrl+"getiplrate")
        .then(res => {
            console.log(res.data);
            setDataIplRate(res.data[0])
            setTarifIpl(res.data[0].ipl_rate)

        })
    }

    const getInternetRate = () => {
        axios.get(baseUrl+"getinternetrate")
        .then(res => {
            console.log(res.data);
            setDataInternetRate(res.data)
        })

    }

    const checkNum = (e, fun) => {
        // console.log(e);
        if(e === undefined || e <= 0){
            fun(0)
        }else{
            fun(parseInt(e))
        }
    }


    const saveNewIpl = (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios.patch(baseUrl+"editiplrate", {
            ipl_rate,
            periodestart_iplrate,
            periodeend_iplrate,
            id: dataIplRate.id
        }).then(res => {
            setIsLoading(false)
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    setIplRate(0)
                    setPeriodeStartIplRate("")
                    setPeriodeEndIplRate("")
                    getIplRate()
                }
              })
        }).catch(err => {
            console.log(err);
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })

    }

    const saveNewInternet = (e) => {
        

        console.log(tarif_internet);
        console.log(periodestart_internetrate);
        console.log(periodeend_internetrate);
        console.log(id_internet);
        e.preventDefault()
        setIsLoading(true)
        axios.patch(baseUrl+"editinternetrate",{
            tarif_internet,
            speed_internet,
            periodestart_internetrate,
            periodeend_internetrate,
            id: id_internet
        }).then(res => {
            setIsLoading(false)
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    setTarifInternet(0)
                    setPeriodeStartInternet("")
                    setPeriodeEndInternet("")
                    getInternetRate()
                }
              })
        }).catch(err => {
            console.log(err);
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })

    }
    

    
        return(
            <Sidebar menuItems={settingConfig} >
            <main id='newcustomer-container' >
                <form>
                <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/setting")}} />
                
                <h4>Mazenta Residence</h4>
                <h2>IPL Rate</h2>

                {/* <Construct/> */}
                <h3>Tarif IPL Lama:</h3>
                <div class="row">
                    <div class="column">
                        <label for="name">Tarif IPL Rate: </label>
                        <input disabled value={tarifIpl.toLocaleString('IN')}  type="text" id="no-nup" placeholder="Nama"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">Periode Awal: </label>
                        <input disabled value={dataIplRate.periodestart_iplrate} type="month" id="no-nup"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Periode Akhir: </label>
                        <input disabled value={dataIplRate.periodeend_iplrate} type="month" id="no-cusomer"/>
                    </div>
                </div>

                <h3>Tarif IPL Baru:</h3>
                <div class="row">
                    <div class="column">
                        <label for="name">Tarif IPL Rate: </label>
                        <CurrencyInput  
                        value={ipl_rate}
                        onValueChange={(e) => {checkNum(e, (setIplRate))}}  
                        id="input-example"
                        name="input-name"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">Periode Awal: </label>
                        <input  value={periodestart_iplrate} onChange={(e) => {setPeriodeStartIplRate(e.target.value)}} type="month" id="no-nup"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Periode Akhir: </label>
                        <input  value={periodeend_iplrate}  onChange={(e) => {setPeriodeEndIplRate(e.target.value)}} type="month" id="no-cusomer"/>
                    </div>
                </div>
                
                <button onClick={(e) => {saveNewIpl(e)}} >Submit</button>
            </form>
            </main>
            </Sidebar>
        )
    

}

export default SettingRate;