import React, { useEffect, useState,useRef } from "react";
import {
    FaArrowLeft,
    FaPrint
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {baseUrl} from '../../Config/URL'
import {dateFormater} from "../../components/Reuse.jsx"
import './ReportingIpl.css'
import {loadingHandler} from '../../components/Loading'
import Swal from 'sweetalert2';
import {useReactToPrint} from 'react-to-print'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const YearReporting = () => {
    const [dateFrom, setDateFrom] = useState("")

    //All Unit Report
    const [allUnit, setAllUnit] = useState([])

    //Ipl Year Unit
    const [iplYearUnit, setIplYearUnit] = useState([])


    const navigate = useNavigate()
    const componentRef = useRef()

    const searchDate = (e) => {
       getIplYear(e)
        
    }

    useEffect(() => {
        getAllUnit()
        initReport()
    }, [])

    const getAllUnit = () => {
        axios.get(baseUrl+"getallunitreport")
        .then(res => {
            //console.log(res.data);
            console.log('then');
            setAllUnit(res.data)
        }).catch(err => {
            console.log(err);
        })
        // .finally(() => {
        //     setIsLoading(prevIsloading => !prevIsloading)
        //     console.log("finnal");
        //     console.log(isLoading);
        // })
    }

    const initReport = () => {
        const d = new Date()
        const year = d.getFullYear()
        getIplYear(year)
        
        
    }

    const getIplYear = (e) => {
        axios.post(baseUrl+"getiplyearreportunit", {
            year: e
        }).then(res => {
            console.log(res.data);
            setIplYearUnit(res.data)
        })
    }

    const filteredForActUnit = (e, p) => {
        // const filteredFor = p.forecast
    }

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Laporan_Pendapatan_Estate_Mazenta_",
        onAfterPrint: () => {
            Swal.fire({
                title: 'Done',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
        }
    })

    const expandCollapse = (e) => {
        const action = document.getElementById(e)

        
            if(action.style.display === "contents"){
                action.style.display = "none"
            }else{
                action.style.display  = "contents"
            }
    

        

        
        // //console.log(action.style.display);
    }


    return(
        <main id="unit-main" >
                    <div className="unit-container" >
                        <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/reporting`)}} />
                        <section className="search-group" >
    
                            <input type="date" placeholder="Cari No. Unit/Nama" className="search-box" onChange={(e) => (setDateFrom(e.target.value))} />
                            {/* <DatePicker selected={dateFrom} showYearPicker dateFormat={"yyyy"} onChange={(e) => (setDateFrom(e))}/> */}
                            <button onClick={() => {searchDate(dateFrom)}} className="search-button" >Cari</button>
                            <button onClick={initReport} className="search-button" >Reset</button>
                            {/* <button  className="search-button" >Reset</button> */}
    
                        </section>
                        
                        <div className='cetak-button-container' >
                            <FaPrint className='cetak-button' size={30} onClick={printHandler}/>
                            <text>Print</text>
                        </div>
                        <section ref={componentRef} className="list-antrian-report" >
                       
                        <h1>Laporan/Tahun</h1>
                        
                        <table class="greyGridTable-report">

                            <thead>
                                    <tr>
                                    <th rowSpan="2">Keterangan</th>
                                    <th colSpan={"2"} >Jan</th>
                                    <th colSpan={"2"} >Feb </th>
                                    <th colSpan={"2"} >Mar </th>
                                    <th colSpan={"2"} >Apr </th>
                                    <th colSpan={"2"} >Mei </th>
                                    <th colSpan={"2"} >Jun </th>
                                    <th colSpan={"2"} >Jul </th>
                                    <th colSpan={"2"} >Agu </th>
                                    <th colSpan={"2"} >Sep </th>
                                    <th colSpan={"2"} >Okt </th>
                                    <th colSpan={"2"} >Nov </th>
                                    <th colSpan={"2"} >Des </th>
                                    </tr>
                                    <tr>
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    

                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    
                                    <th>Forecast</th>
                                    <th>Actual</th>
                                    

                                    </tr>
                                </thead>
                                <tbody>

                                {/* IPL YEAR */}
                                <tr className="row-head" >

                                    <td onClick={() => {expandCollapse("ipl-unit")}} className="first-col" style={{fontStyle: "italic", backgroundColor: "rgb(254, 249, 214)"}}>Pendapatan IPL</td>  

                                </tr>

                                <div id="ipl-unit">
                                    {allUnit.map((item, index) => {
                                        return(
                                            <tr key={item.no_unit}  className="row-body">
                                                <td className="first-col" style={{fontWeight: "normal"}}>{item.no_unit}</td> 
                                                {filteredForActUnit(item.no_unit, iplYearUnit)}   
                                            </tr>
                                        )
                                    })}
                                </div>


                                </tbody>

                        </table>

                        
    
    
    
                        
    
    
                        </section>
                    </div>
                </main>
    )


}

export default YearReporting;

