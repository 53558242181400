import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Sidebar from "../../components/Sidebar";
import { estateConfig, settingConfig } from "../../components/PageConfig";

const EditCustomerRate = () => {
    const [dataAllCustomer, setDataAllCustomer] = useState([])
    const [iplActive, setIplActive] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getIplActiveHandler()
    },[])


    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        
      }
    
      const getIplActiveHandler = () => {
        axios.get(baseUrl+'getiplactive')
        .then(res => {
            console.log(res.data);
            setIplActive(res.data)
        }).catch(err => {
            console.log(err);
        })
    }


    return(
        <Sidebar menuItems={settingConfig} >
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/setting`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <h1>Edit Customer Rate</h1>
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                        <tr>
                            <th className="no">No.</th>
                            <th>No. Unit</th>
                            <th>Nama</th>
                            <th>Luas Tanah</th>
                            <th>IPL Rate</th>
                            <th>Periode Awal IPL</th>
                            <th>Periode Akhir IPL</th>
                            <th>Internet Rate</th>
                            <th>Periode Awal Internet</th>
                            <th>Periode Akhir internet</th>
                            <th>Email</th>
                            <th>No. Hp</th>
                            </tr>
                        </thead>
                        <tbody>

                        {iplActive.map((item, index) => {
                                return(
                                    <tr onClick={() => {navigate("/editdetailcustomerrate", {state: item})}}>
                                        <td className="no">{index+1}</td>
                                        <td>{item.no_unit}</td>
                                        <td>{item.nama}</td>
                                        <td>{item.luas_tanah} m<sup>2</sup></td>
                                        <td>Rp.{item.nominal.toLocaleString("IN")}</td>
                                        <td>{item.periode_start.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        <td>{item.periode_end.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        <td>Rp.{item.tarif_internet.toLocaleString('IN')}</td>
                                        <td>{ item.periodestart_internet !== null ? item.periodestart_internet.replace(/(\d+)(st|nd|rd|th)/, "$1") : "-"}</td>
                                        <td>{ item.periodeend_internet !== null ? item.periodeend_internet.replace(/(\d+)(st|nd|rd|th)/, "$1") : "-"}</td>
                                        <td>{item.alamat_email}</td>
                                        <td>+{item.no_hp1}</td>
                                    </tr>
                                )
                            })}

                            

                        </tbody>
                        
                    </table>
                    {iplActive.length === 0 && <h3>Belum Ada Data</h3>}



                    



                    </section>
                </div>
            </main>
            </Sidebar>
    )


}

export default EditCustomerRate;