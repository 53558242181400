import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import ExcelJS from 'exceljs';
import Sidebar from "../../components/Sidebar";
import { salesConfig } from "../../components/PageConfig";


const UploadCustomer = () => {
    const [uploadFile, setUploadFile] = useState("")

    const descTable = [
            {
                name:"Nama",
                keterangan: "Nama Customer",
                nilaiKosong: "Tidak Boleh Kosong",
                contohInput : "James"
            },
            {
                name:"No. Identitas",
                keterangan:"No Identitas Customer",
                nilaiKosong: "Tidak Boleh Kosong",
                contohInput : "1823242xxx"
            },
            {
                name:"Alamat",
                keterangan:"Alamat Customer",
                nilaiKosong:"Tidak Boleh Kosong",
                contohInput : "JL. Indonesia Raya"
            },
            {
                name:"Kota",
                keterangan:"Kota Customer",
                nilaiKosong:"Tidak Boleh Kosong",
                contohInput : "Jakarta"
            },
            {
                name:"Tanggal Lahir",
                keterangan:"Tanggal Lahir Customer",
                nilaiKosong: "-",
                contohInput : "12/12/1998"
            },
            {
                name:"NPWP",
                keterangan:"No. NPWP Customer",
                nilaiKosong:"-",
                contohInput : "1821329xxx"
            },
            {
                name:"No. HP",
                keterangan:"No. Handphoe Customer",
                nilaiKosong:"Tidak Boleh Kosong",
                contohInput : "08123456xxx"
            },
            {
                name:"Email",
                keterangan:"Email Customer",
                nilaiKosong:"Tidak Boleh Kosong",
                contohInput : "james123@exp.com"
            },
                
            {
                name:"Alamat Surat Menyurat",
                keterangan: "Alamt Surat Menyurat Customer",
                nilaiKosong:"Tidak Boleh Kosong",
                contohInput : "JL, Indonesia Raya"
            },
            {
                name:"No. Unit",
                keterangan:"Nomor Unit Customer",
                nilaiKosong:"-",
                contohInput : "MR-M7-06"
            },
            {
                name:"No. Kontrak",
                keterangan: "Nomor Kontrak",
                nilaiKosong:"-",
                contohInput : "MR/SPU/X/32/00000"
            },
            {
                name:"Tanggal Kontrak",
                keterangan: "Tanggal Kontrak",
                nilaiKosong:"-",
                contohInput : "12/12/1998"
            }
    ]

    const navigate = useNavigate()

    const downloadTemplate = () => {

        //axios.get(baseUrl+"templatedownloadbast")
        axios({
            url: baseUrl+"templatedownloadcustomer",
            method: 'GET',
            responseType: 'blob',
          })
        .then(res => {
            console.log(res);
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Template CUSTOMER.xlsx'; // Sesuaikan dengan nama file yang sesuai.
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })

    }

    const uploadBast = () => {
        if(uploadFile === ""){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text:"Tidak ada file yang dipilih!!",
                allowOutsideClick: false
              })
        }else{
            const formData = new FormData()
            formData.append('excelFile', uploadFile)
            const fileReader = new FileReader();

            fileReader.onload = async () => {
                const buffer = fileReader.result;
                const workbook = new ExcelJS.Workbook();
                const readExcel = await workbook.xlsx.load(buffer);
        
                const worksheet = readExcel.getWorksheet(1); // Ambil worksheet pertama
        
                // Ambil baris pertama dari worksheet
                const firstRow = worksheet.getRow(2).values;
                
                // Data sekarang adalah array yang berisi baris pertama dari worksheet
                console.log(firstRow);

            
               

                if(firstRow.length === 13){
                    axios.post(baseUrl+"uploadcustomer", formData)
                    .then(res => {
                        console.log(res.data);
                        Swal.fire({
                            title: 'Success',
                            text: res.data.msg,
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false
                        })
                    }).catch(err => {
                        console.log(err);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.response.data.msg,
                            allowOutsideClick: false
                        })
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Format Template Salah!",
                        allowOutsideClick: false
                    })
                }
            };
      
            fileReader.readAsArrayBuffer(uploadFile);

            // axios.post(baseUrl+"uploadbast", formData)
            // .then(res => {
            //     console.log(res.data);
            //     Swal.fire({
            //         title: 'Success',
            //         text: res.data.msg,
            //         icon: 'success',
            //         showConfirmButton: true,
            //         confirmButtonColor: '#3085d6',
            //         confirmButtonText: 'Ok',
            //         allowOutsideClick: false
            //       })
            // }).catch(err => {
            //     console.log(err);
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: err.response.data.msg,
            //         allowOutsideClick: false
            //       })
            // })
        }
    }

    console.log(uploadFile);

    return(
        <Sidebar menuItems={salesConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Upload Customer</h2>
                    <section className="upload-section" >
                        <button onClick={downloadTemplate}>Download Template</button>
                        <button onClick={uploadBast}>Upload</button>
                        <input onChange={(e) => setUploadFile(e.target.files[0])} type="file" accept=".xlsx"/>
                    </section>

                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr className="no">
                            <th>No.</th>
                            <th>Nama</th>
                            <th>Keterangan</th>
                            <th>Nilai Kosong</th>
                            <th>Contoh Input</th>
                            </tr>
                        </thead>
                        <tbody>

                        {descTable.map((item, i) => {
                            return(
                                <tr>
                                    <td className="no">{i+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.keterangan}</td>
                                    <td>{item.nilaiKosong}</td>
                                    <td>{item.contohInput}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>


                    </section>

                    
                </div>
            </main>
            </Sidebar>
    )

}

export default UploadCustomer;