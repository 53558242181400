import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft, FaRegEdit, FaTrash
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Sidebar from "../../components/Sidebar";
import { salesConfig } from "../../components/PageConfig";
import Swal from 'sweetalert2';
import { MdOutlineDeleteForever, MdOutlineEdit } from "react-icons/md";

const EditCustomer = () => {
    const [dataAllCustomer, setDataAllCustomer] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getAllCustomer()
    },[])


    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        
      }
    
    const getAllCustomer = () => {
        axios.get(baseUrl+"getalldatacustomer")
        .then(res => {
            console.log(res.data);
            setDataAllCustomer(res.data)
        }).catch(err => {
            console.log(err);
        })
    }
    

    const deleteAlert = (e) => {
        Swal.fire({
            title: "Hapus?",
            html: `<h2></h2>Anda Yakin Ingin Menghapus Data Customer Dengan Nama:</h2> ${e.nama}<br><br>
            <h4>Note: Data yang telah terhapus tidak akan dapat di pulihkan kembali!</h4> `,
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Yes!',
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
             deleteHandler(e)
                
            }
          })
    }


    const deleteHandler = (e) => {
        console.log(e);
        axios.patch(baseUrl+"deletecustomer", {
           data: {
               id: e.id_customer,
               no_unit: e.no_unit,
           },
       }).then(res => {
           console.log(res.data);
           getAllCustomer()
           Swal.fire({
               title: 'Success',
               icon: 'success',
               showConfirmButton: true,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Ok'
             })
          
       }).catch(err => {
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: err.response.data.msg,
               allowOutsideClick: false
           })
       })
   }

    return(
        <Sidebar menuItems={salesConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Edit Customer</h2>
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Customer</th>
                            <th>No.Hp</th>
                            <th>Alamat Email</th>
                            <th>Unit</th>
                            <th>Tipe</th>
                            <th>Luas Tanah</th>
                            <th>Luas Bangunan</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataAllCustomer.map((item, index) => {
                                return(
                                    <>
                                       <tr className="no-hover">
                                            <td>{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.email}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.luas_tanah} m<sup>2</sup></td>
                                            <td>{item.luas_bangunan} m<sup>2</sup></td>
                                            <td style={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center",alignItems: "center"}}>
                                                <span onClick={() => {navigate("/editdetailcustomer", {state: item.id_customer})}}><MdOutlineEdit size={20} /> </span>
                                                <span onClick={() => {deleteAlert(item)}}><MdOutlineDeleteForever size={20} /></span>
                                            </td>
                                        </tr> 
                                    </>
                                )
                            })}

                            

                        </tbody>
                    </table>



                    



                    </section>
                </div>
            </main>
            </Sidebar>
    )


}

export default EditCustomer;