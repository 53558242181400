import React, { useEffect, useState } from "react";
import {
    FaArrowLeft, FaPrint
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";
import { MdOutlinePayments } from "react-icons/md";
import Swal from "sweetalert2";

const Kwitansi = () => {
    const [paidList, setPaidList] = useState([])
    const [dataUnpaid, setDataUnpaid] = useState([])
    const [openData, setOpenData] = useState("")
    const [tgl_bayar, setTglBayar] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        getDataPaid()
    },[])
    
    const getDataPaid = () => {
        axios.get(baseUrl+'getpaidinvoice')
        .then(res => {
            console.log(res.data);
            setPaidList(res.data)
        })
    }

    const navigate = useNavigate()

      const generateRef = (e, cond) => {


        let id = 0
        let tglInit = new Date()
        let tgl = tglInit.getMonth(e.created_at)+1
        let tglRoman = ""
        let year = tglInit.getFullYear()
  
        if(e < 10){
          id = "00"+e
        }else if(e >= 10 && e < 100){
          id = "0"+e
        }else{
          id = e
        }
  
  
        switch (tgl) {
          case 1:
            tglRoman = "I"
            break;
          case 2:
              tglRoman = "II"
            break;
          case 3:
      tglRoman = "III";
            break;
          case 4:
      tglRoman = "IV";
            break;
          case 5:
  tglRoman = "V";
            break;
          case 6:
    tglRoman = "VI";
            break;
          case 7:
    tglRoman = "VII";
            break;
          case 8:
          tglRoman = "VIII";
            break;
          case 9:
              tglRoman = "IX";
            break;
          case 10:
          tglRoman = "X";
            break;
          case 11:
            tglRoman = "XI";
            break;
          case 12:
            tglRoman = "XII";
            break;
      }
  
        // console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
        // console.log(dataInvoice[0].id_invoice);
        // setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)
        let refrenceGen = ""
        if(cond === "inv"){
            refrenceGen = id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year
        }else if(cond === "kwt"){
            refrenceGen = id+'/'+'GGI-KWT/IPL-IT/MR/'+tglRoman+'/'+year
        }
        return refrenceGen
  
      }


      const paidStatus = (e) => {
        // setIsLoading(true)
        const id = openData.id_invoice
        // console.log(id);
        // console.log(tgl_bayar);

        axios.put(baseUrl+"updatestatusinvoice", {
            tgl_bayar,
            id
        }).then(res => {
            setIsLoading(false)            
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    
                    closeModalHandler()
                    setIsLoading(false)
                }
              })
            getDataPaid()
        }).catch(err => {
            setIsLoading(false)            

            // console.log("Err_"+isLoading);
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    setIsLoading(false)                }
              })
        })
    }


      const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        setOpenData(e)
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setTglBayar("")
        setOpenData("")
      }

    return(
      <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Kwitansi</h2>
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th className="no">No.</th>
                            <th>No. Invoice</th>
                            <th>No. Kwitansi</th>
                            <th>Nama</th>
                            <th>Jatuh Tempo</th>
                            <th>Tanggal Bayar</th>
                            <th>No.Unit</th>
                            {/* <th>IPL</th>
                            <th>Kuantitas IPL</th>
                            <th>Harga Internet</th>
                            <th>Kuantitas Internet</th> */}
                            
                            <th>Grand Total</th>
                            <th>Tanggal Invoice</th>
                            <th>Status</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paidList && paidList.map((item, index) => {
                                return(
                                    <>
                                        <tr className="no-hover">
                                            <td className="no">{index+1}</td>
                                            <td>{generateRef(item.no_invoice, "inv")}</td>
                                            <td>
                                              {
                                                item.status_invoice === 1
                                                ? <>{generateRef(item.no_kwitansi, "kwt")}</>
                                                : <></>
                                              }
                                              </td>
                                            <td>{item.nama_customer}</td>
                                            <td>{item.date_due.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                            <td>
                                            {
                                                item.status_invoice === 1
                                                ? <>{item.paid_date.replace(/(\d+)(st|nd|rd|th)/, "$1")}</>
                                                : <></>
                                              }
                                            </td>
                                            <td>{item.no_unit_invoice}</td>
                                            {/* <td>Rp.{item.ipl_invoice.toLocaleString('IN')}</td>
                                            <td>{item.kuantitas_ipl} Bulan</td>
                                            <td>Rp.{item.internet_invoice.toLocaleString('IN')}</td>
                                            <td>{item.kuantitas_internet} Bulan</td> */}
                          
                                            <td>Rp.{item.grandtotal_invoice.toLocaleString('IN')}</td>
                                            <td>{item.date_full}</td>
                                            <td>
                                            {
                                                item.status_invoice === 1
                                                ? <>Sudah Bayar</>
                                                : <>Belum Bayar</>
                                              }
                                            </td>
                                            <td style={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center",alignItems: "center"}}>
                                            {
                                                item.status_invoice === 1
                                                ? <span  onClick={() => {navigate('/cetakkwitansi', {state:item})}}><FaPrint className="action-icon" size={20} /> Print Kwitansi </span>
                                                : <span onClick={() => {openModalHandler(item)}} ><MdOutlinePayments className="icon-action" size={20} /> Bayar</span>
                                              }
                                                
                                                
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                    {paidList.length === 0 || !paidList && <h3 className="no-data">Belum Ada Data</h3>}


                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Payment</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Tanggal Bayar :  </label>
                    <input value={tgl_bayar} onChange={(e)=>{setTglBayar(e.target.value)}} type="date"   />
                    
                    <div className = "form-button" > 
                    <button onClick={(e)=>{paidStatus(e)}} className = "profile-save btn "   >Paid</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>
    )

}

export default Kwitansi;