import React, { useEffect, useState } from 'react';
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useLocation, useNavigate} from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import { baseUrl } from '../../Config/URL';
import Swal from 'sweetalert2';
import Sidebar from '../../components/Sidebar';
import { estateConfig } from '../../components/PageConfig';

// import './FormDataIPL.css'

const FormDataIPL = () => {
    const [periodFrom, setPeriodFrom] = useState("")
    const [periodTo, setPeriodTo] = useState("")
    const [nominalIpl, setNominalIpl] = useState("")
    const [id_internet, setIdInternet] = useState(null)
    const [dataCust, setDataCust] = useState("")
    const [dataInternetRate, setDataInternetRate] = useState([])
    const [periodestart_internet, setPeriodeStartInternet] = useState(null)
    const [periodeend_internet, setPeriodeEndInternet] = useState(null)
    const [dataIplRate, setDataIplRate] = useState({})

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const {state} = location
        getIplRate()
        setDataCust(state)
        // setNominalIpl(state.luas_tanah*dataIplRate.ipl_rate)
        getInternetRate()
    },[])


    useEffect(() => {
        loading2(isLoading)
    })

    const getInternetRate = () => {
        axios.get(baseUrl+"getinternetrate")
        .then(res => {
            console.log(res.data);
            setDataInternetRate(res.data)
        })
    }

    const getIplRate = () => {
        const {state} = location
        axios.get(baseUrl+"getiplrate")
        .then(res => {
            console.log();
            setDataIplRate(res.data[0])
            setNominalIpl(state.luas_tanah*res.data[0].ipl_rate)
        })
    }

    const saveNewIplHandler = (e) => {
        setIsLoading(true)
        e.preventDefault()
        console.log("nominal_"+nominalIpl);
        console.log("periodFrom_"+periodFrom);
        console.log("periodTo_"+periodTo);
        console.log("idCust_"+dataCust.id_customer);

        const nominal = nominalIpl
        const periode_start = periodFrom
        const periode_end = periodTo
        const id_customer = dataCust.id_customer
        const status_ipl = 1
        
        console.log(periode_start.split("-")[0]);

        axios.post(baseUrl+"postnewipl",{
            nominal: nominal,
            periode_start: periode_start,
            periode_end: periode_end,
            id_customer: id_customer,
            status_ipl: status_ipl,
            id_internet: id_internet,
            periodestart_internet: periodestart_internet,
            periodeend_internet: periodeend_internet
        }).then(res => {
            setIsLoading(false)
            console.log(res);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    navigate("/daftaripl")
                }
              })
        }).catch(err => {
            setIsLoading(false)
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    setIsLoading(false)                
                }
              })
        })
        

    }

    const loading2 = () => {
        if(isLoading){
            return(
                Swal.fire({
                    icon: "info",
                    title: "Loading",
                    text: "Please Wait",
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowEnterKey: false,
                    allowOutsideClick: false

                    
                })
            )
        }else{return null}
    }

    console.log(id_internet);
    
    return (
        <Sidebar menuItems={estateConfig}>
        <main id='newcustomer-container' >

            {/* <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/customer")}} />
            <h1>Add New Customer</h1> */}


            <form>
            <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/daftaripl")}} />
            <h1>Pendaftaran Rate (IPL/Internet)</h1>
            <h3>Data Customer</h3>
            <div class="row">
                <div class="column">
                    <label for="nama">Nama: </label>
                    <input disabled type="text" id="nama" placeholder={dataCust.nama}/>
                </div>
                <div class="column">
                    <label for="unitcode">No. unit: </label>
                    <input disabled type="text" id="unitcoder" placeholder={dataCust.no_unit}/>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="project">Tipe Bangunan: </label>
                    <input disabled type="text" id="project" placeholder={dataCust.tipe_bangunan}/>
                </div>
                <div class="column">
                    <label for="no-hp">No. Hp: </label>
                    <input disabled type="text" id="no-hp" placeholder={dataCust.no_hp1}/>
                </div>
            </div>
            <div class="row">
                
                <div class="column">
                    <label for="email">Email: </label>
                    <input disabled type="text" id="email" placeholder={dataCust.alamat_email}/>
                </div>
                <div class="column">
                    <label for="project">Luas Bangunan: </label>
                    <input disabled type="text" id="project" placeholder={dataCust.luas_bangunan}/>
                </div>

            </div>
            <div class="row">
                
                
                <div class="column">
                    <label for="project">Luas Tanah: </label>
                    <input disabled type="text" id="project" placeholder={dataCust.luas_tanah}/>
                </div>

            </div>


            <h3>IPL Rate</h3>

            <div class="row">
                <div class="column">
                    <label for="ipl-rate">Nominal IPL Rate (Luas Tanah x {dataIplRate.ipl_rate}): </label>
                    <CurrencyInput
                        disabled
                        id="input-example"
                        name="input-name"
                        placeholder="0"
                        decimalsLimit={2}
                        value={nominalIpl}
                        />
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <label for="periode-dari">Periode (Dari Bulan): </label>
                    <input onChange={(e) => {setPeriodFrom(e.target.value)}} type="date" id="periode-dari" placeholder="Periode IPL Rate"/>
                </div>
                <div class="column">
                    <label for="periode-ke">Periode (Ke Bulan): </label>
                    <input onChange={(e) => {setPeriodTo(e.target.value)}} type="date" id="periode-ke" placeholder="Periode IPL Rate"/>
                </div>
                
            </div>


            <h3>Internet</h3>

            <div class="row">
                <div class="column">
                    <label for="ipl-rate">Nominal Internet: </label>
                    <select onChange={(e) => {setIdInternet(e.target.value)}} >
                        <option value={null}>--Select Internet--</option>
                        {dataInternetRate.map((item, index) => {
                            return(
                                <option value={item.id} >{item.speed_internet} Mbps (Rp.{item.tarif_internet.toLocaleString('IN')})</option>
                            )
                        })}
                        
                    </select>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <label for="periode-dari">Periode Internet (Dari Bulan): </label>
                    <input onChange={(e) => {setPeriodeStartInternet(e.target.value)}} type="date" id="periode-dari" placeholder="Periode IPL Rate"/>
                </div>
                <div class="column">
                    <label for="periode-ke">Periode Internet (Ke Bulan): </label>
                    <input onChange={(e) => {setPeriodeEndInternet(e.target.value)}}  type="date" id="periode-ke" placeholder="Periode IPL Rate"/>
                </div>
                
            </div>

            {/* <div class="row">
                <div class="column">
                    <label for="rt-rw">RT/RW: </label>
                    <input type="text" id="rt-rw" placeholder="RT/RW"/>
                </div>
                <div class="column">
                    <label for="kelurahan">Kelurahan: </label>
                    <input type="text" id="kelurahan" placeholder="Kelurahan"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kecamatan">Kecamatan: </label>
                    <input type="text" id="kecamatan" placeholder="Kecamatan"/>
                </div>
                <div class="column">
                    <label for="kota-kabupaten">Kotamadya/Kabupaten: </label>
                    <input type="text" id="kota-kabupaten" placeholder="Kotamadya/Kabupaten"/>
                </div>
            </div>
            
            <div class="row">
                <div class="column">
                    <label for="kode-pos">Kode Pos: </label>
                    <input type="number" id="kode-pos" placeholder="Kode Pos"/>
                </div>
            </div> */}
            
            


            
            
            <button onClick={saveNewIplHandler} >Submit</button>
        </form>

            
        </main>
        </Sidebar>
    );
};

export default FormDataIPL;