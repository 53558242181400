import React, {Component} from "react";

import './Dashboard.css'

import UserIcon from '../../Assets/User.png'
import Dollar from '../../Assets/Dollar.png'
import Unpaid from '../../Assets/Invoice.png'
import axios from "axios";
import { baseUrl } from "../../Config/URL";

class Dashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            unpaid: "",
            paid:"",
            user: ""
        }
    }

    componentDidMount(){
        this.getUnpaidTotal()
        this.getPaidTotal()
        this.getUserTotal()
        const time = new Date()

        console.log(time.getMilliseconds());
    }

    getUnpaidTotal = () => {
        axios.get(baseUrl+"totalunpaid")
        .then(res => {
            console.log(res.data[0]);
            this.setState({unpaid:res.data[0]})
        })
    }

    getPaidTotal = () => {
        axios.get(baseUrl+"totalpaid")
        .then(res => {
            console.log(res.data[0]);
            this.setState({paid:res.data[0]})
        })
    }

    getUserTotal = () => {
        axios.get(baseUrl+"totaluser")
        .then(res => {
            console.log(res.data[0]);
            this.setState({user:res.data[0]})
        })
    }

    render(){
        
        return(
            <main id="dashboard-main" >
                
                <section className="card-group" >

                    <div className="card" >

                        <div className="icon-flag" >
                            <img src={UserIcon} />
                        </div>

                        <div className="info" >
                            <h2>User:</h2>
                            <h3>{this.state.user.total_user}</h3>
                        </div>
                        

                    </div>
                    
                    <div className="card" >

                        <div className="icon-flag paid" >
                            <img src={Dollar} />
                        </div>

                        <div className="info" >
                            <h2>Paid:</h2>
                            <h3>{this.state.paid.total_paid}</h3>
                        </div>
                        

                    </div>

                    <div className="card" >

                        <div className="icon-flag unpaid" >
                            <img src={Unpaid} />
                        </div>

                        <div className="info" >
                            <h2>Unpaid:</h2>
                            <h3>{this.state.unpaid.total_unpaid}</h3>
                        </div>
                        

                    </div>

                </section>

            </main>
        )
    }

}

export default Dashboard;