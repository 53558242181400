import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { FaHouseUser,FaHandshake, FaFileInvoiceDollar,FaFileExcel, FaClipboardList } from 'react-icons/fa';
import { BsFillFileCheckFill, BsTable, BsFillCalendarWeekFill } from 'react-icons/bs';
import { AiOutlineFileAdd } from 'react-icons/ai';

import './Reporting.css'
import Sidebar from '../../components/Sidebar';
import { financeConfig } from '../../components/PageConfig';


class Reporting extends Component{


    render(){
        return(
            <Sidebar menuItems = {financeConfig} >
            <main id='sales-container' >
                
                    <section className='sales-group-card' >
                    

                        <h1>Finance</h1>

                    </section>
                
                
            </main>
            </Sidebar>
        )
    }


}

export default Reporting;