import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";
import {noData} from "../../components/Reuse"

const CetakIPL = () => {
    const [payDate, setPayDate] = useState("")
    const [dataInvoice, setDataInvoice] = useState([])
    const [refrence, setRefrence] = useState("")
    const [search, setSearch] = useState("")


    useEffect(() => {
        getUnpaidInvoice()
    }, [])

    
    const generateRef = (e) => {


        let id = 0
        let tglInit = new Date()
        let tgl = tglInit.getMonth(e.created_at)+1
        let tglRoman = ""
        let year = tglInit.getFullYear()
  
        if(e.id_invoice < 10){
          id = "00"+e.id_invoice
        }else if(e.id_invoice >= 10 && e.id_invoice < 100){
          id = "0"+e.id_invoice
        }else{
          id = e.id_invoice
        }
  
  
        switch (tgl) {
          case 1:
            tglRoman = "I"
            break;
          case 2:
              tglRoman = "II"
            break;
          case 3:
      tglRoman = "III";
            break;
          case 4:
      tglRoman = "IV";
            break;
          case 5:
  tglRoman = "V";
            break;
          case 6:
    tglRoman = "VI";
            break;
          case 7:
    tglRoman = "VII";
            break;
          case 8:
          tglRoman = "VIII";
            break;
          case 9:
              tglRoman = "IX";
            break;
          case 10:
          tglRoman = "X";
            break;
          case 11:
            tglRoman = "XI";
            break;
          case 12:
            tglRoman = "XII";
            break;
      }
  
  
  
        console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
        console.log(dataInvoice[0].id_invoice);
        const refrenceGen = id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year
        // setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)
        return refrenceGen
  
      }


    const getUnpaidInvoice = () => {
        axios.get(baseUrl+"getunpaidinvoice")
        .then(res => {
            console.log(res.data);
            setDataInvoice(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const searchDataUnpaid = () => {
      axios.post(baseUrl+"searchdataunpaidinvoice",{
          nama:search
      })
      .then(res => {
          console.log(res.data);
          setDataInvoice(res.data)
      }).catch(err => {
          console.log(err);
      })
  }

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setPayDate("")
        
      }
    


    const navigate = useNavigate()

    console.log(dataInvoice);

    return(
        <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Print Invoice </h2>
                    <section className="search-group" >

                        <input onChange={(e) => {setSearch(e.target.value)}} type="search" placeholder="Cari No. Unit/Nama" className="search-box" />
                        <button onClick={searchDataUnpaid} className="search-button" >Cari</button>
                        <button onClick={getUnpaidInvoice} className="search-button" >Reset</button>

                    </section>
                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th className="no">No.</th>
                            <th>No.Referensi</th>
                            <th>Customer</th>
                            <th>No.Hp</th>
                            <th>Alamat Email</th>
                            <th>No.Unit</th>
                            <th>Grand Total</th>
                            {/* <th>IPL</th>
                            <th>Kuantitas IPL</th>
                            <th>Harga Internet</th>
                            <th>Kuantitas Internet</th>
                            <th>Diskon</th>
                            <th>Denda</th>
                            <th>Tanggal Invoice</th> */}
                            <th>Tanggal Jatuh Tempo</th>
                            </tr>
                        </thead>
                        <tbody>
                          
                            {dataInvoice && dataInvoice.map((item, index) => {
                                return(
                                    <>
                                    
                                    <tr onClick={()=>{navigate("/detailcetak",{state:item})}}>
                                        <td className="no">{index+1}</td>
                                        <td>{generateRef(item)}</td>
                                        <td>{item.nama_customer}</td>
                                        <td>{item.no_telp_invoice}</td>
                                        <td>{item.email_invoice}</td>
                                        <td>{item.no_unit_invoice}</td>
                                        <td>Rp.{item.grandtotal_invoice.toLocaleString("IN")}</td>
                                        <td>{item.date_due.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        {/* <td>Rp.{item.ipl_invoice.toLocaleString("IN")}</td>
                                        <td>{item.kuantitas_ipl} Bulan</td>
                                        <td>Rp.{item.internet_invoice.toLocaleString("IN")}</td>
                                        <td>{item.kuantitas_internet} Bulan</td>
                                        <td>Rp.{}</td>
                                        <td>Rp.{}</td>
                                        <td>{item.date_full.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td>
                                        <td>{item.date_due.replace(/(\d+)(st|nd|rd|th)/, "$1")}</td> */}
                                    </tr>

                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                    {dataInvoice.length === 0 || !dataInvoice && <h3 className="no-data">Belum Ada Data</h3>}



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail Tagihan</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Tgl Bayar :  </label>
                    <input type="date" value={payDate} onChange={(e) => {setPayDate(e.target.value)}}   />
                    {/* <label>Kuantitas: </label>
                    <input type="number"  />
                    <label>Denda: </label>
                    <input type="number"  />
                    <label>Paket: </label>
                    <input type="text"/> */}
                    <div className = "form-button" > 
                    <button className = "profile-save btn " onClick={() => {console.log(payDate);}}  >Paid</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>
    )

}

export default CetakIPL;