import React, {Component, useState, useEffect} from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import './Unit.css'
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import { loadingHandler } from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import {salesConfig} from "./../../components/PageConfig"

const Unit = () => {
    const navigate = useNavigate()
    const [custId, setCustId] = useState("")
    const [custName, setCustName] = useState("")
    const [dataCustNoUnit, setDataCustNoUnit] = useState([])
    const [dataUnitStock, setDataUnitStock] = useState([])
    const [unitId, setUnitId] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getDataCustNoUnit()
        getDataUnitStock()
    },[])

    useEffect(() => {
        loading2(isLoading)
    })

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        setCustName(e.nama)
        setCustId(e.id)
        console.log(e);
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setCustId("")
        setCustName("")
        setUnitId("")
      }


    const getDataCustNoUnit = () => {
        axios(baseUrl+'getcustnounit')
        .then(res => {
            
            setDataCustNoUnit(res.data);
            
        })
    }

    const getDataUnitStock = () => {
        axios(baseUrl+'getunitstock')
        .then(res => {
            // console.log(res.data);
            setDataUnitStock(res.data);
        })
        
    }


    function saveChooseUnit  ()   {
        setIsLoading(true)    
        console.log("start post_"+isLoading);
        // console.log("asd:"+custId);
        // console.log("asd2:"+unitId);

       axios.put(baseUrl+'savechooseunit',{
            id: custId,
            no_unit: unitId

        })
        .then(res => {
            setIsLoading(false)            
            console.log("Work_"+isLoading);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    getDataCustNoUnit()
                    closeModalHandler()
                    getDataUnitStock()
                    setIsLoading(false)
                }
              })
        }).catch(err => {
            setIsLoading(false)            

            // console.log("Err_"+isLoading);
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    setIsLoading(false)                }
              })
            
        })

    }

    

    const loading2 = () => {
        if(isLoading){
            return(
                Swal.fire({
                    icon: "info",
                    title: "Loading",
                    text: "Please Wait",
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowEnterKey: false,
                    allowOutsideClick: false

                    
                })
            )
        }else{return null}
    }

    console.log("loadWork_"+isLoading);
        return(
            <Sidebar menuItems={salesConfig} >
            <main id="unit-main" >
                
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Pilih Unit</h2>
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" onClick = {openModalHandler}  >Cari</button>

                    </section>
                    
                    <section className="list-antrian" >
                        
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                                <th>No.</th>
                                {/* <th>Action</th> */}
                                <th>No. Reservasi/NUP</th>
                                <th>Nama</th> 
                            </tr>
                        </thead>
                        <tbody>

                            {dataCustNoUnit.map((item, index) => {
                                return(
                                    <>
                                        <tr onClick={() => {openModalHandler(item)}}  >
                                            <td>{index+1}</td>
                                            <td>{item.no_reservasi}</td>
                                            <td>{item.nama}</td>
                                        </tr>
                                    </>
                                )
                            })}

                            
                        </tbody>
                    </table>
                    {dataCustNoUnit.length === 0 && <h3 className="no-data">Belum Ada Data</h3>}



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Pilih Unit Untuk {custName}</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Cluster: </label>
                    <select >
                      <option>Mazenta </option>
                    </select>
                    <label>Unit: </label>
                    <select value={unitId} onChange={(e) => {setUnitId(e.target.value)}} >
                        <option value = "" >--Select Unit--</option>
                        {dataUnitStock.map((item, index) => {
                            return(
                                <>
                                    <option value={item.no_unit} >{item.no_unit}</option>
                                </>
                            )
                        })}
                    </select>
                    <div className = "form-button" >
                    {isLoading
                        ? <button disabled >Loading</button>
                        : <button onClick={saveChooseUnit} className = "profile-save btn "  >Simpan</button>
                    }
                    
                    <button className = "profile-cancel  btn" id = "profile-cancel" onClick = {closeModalHandler}  >Keluar</button>
                    </div>
                    {/* <button className="cancel-btn" >Pembatalan</button> */}
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>
        )
    

}

export default Unit;