import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";

const AllCustomer = () => {
    const [dataAllCustomer, setDataAllCustomer] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getAllCustomer()
    },[])


    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        
      }
    
    const getAllCustomer = () => {
        axios.get(baseUrl+"getalldatacustomer")
        .then(res => {
            console.log(res.data);
            setDataAllCustomer(res.data)
        }).catch(err => {
            console.log(err);
        })
    }


    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <h1>All Customer</h1>
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th className="no">No.</th>
                            <th>Nama</th>
                            <th>No.Hp</th>
                            <th>Email</th>
                            <th>No. Unit</th>
                            <th>Tipe Bangunan</th>
                            <th>Luas Tanah</th>
                            <th>Luas Bangunan</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataAllCustomer.map((item, index) => {
                                return(
                                    <>
                                       <tr>
                                            <td className="no">{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>+{item.no_hp1}</td>
                                            <td>{item.email}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.luas_tanah} m<sup>2</sup></td>
                                            <td>{item.luas_bangunan} m<sup>2</sup></td>
                                        </tr> 
                                    </>
                                )
                            })}

                            

                        </tbody>
                    </table>



                    



                    </section>
                </div>
            </main>
    )

}

export default AllCustomer;