import React, {useEffect, useState} from "react";

import './Header.css'
import { FaHouseUser, FaUserPlus, FaHandshake, FaHome , FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {BiLogOut, BiReceipt} from "react-icons/bi"
import logoPict from '../Assets/CHL-Logo2.png'




const Header = () => {
    const navigate = useNavigate()
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const padZero = (number) => {
        // Fungsi untuk menambahkan "0" pada angka kurang dari 10
        return number < 10 ? '0' + number : number;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000);
    
        // Membersihkan interval saat komponen unmount
        return () => clearInterval(intervalId);
      }, []);

      const hours = currentDateTime.getHours();
  const minutes = currentDateTime.getMinutes();
  const seconds = currentDateTime.getSeconds();

  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  const daysOfWeek = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

  const dayOfWeek = daysOfWeek[currentDateTime.getDay()];
  const dayOfMonth = currentDateTime.getDate();
  const month = months[currentDateTime.getMonth()];
  const year = currentDateTime.getFullYear();



    const storage = JSON.parse(sessionStorage.getItem("TokenCHL"))

    const redirectHandler = () =>{
        

        return new Promise((resolve, reject) => {
            navigate("/")
            const error = false
      if(!error) {
        resolve()
      }else{
        reject()
      }
         })
    }

    const removeToken = () => {

        return new Promise((resolve, reject) => {
           sessionStorage.removeItem("TokenCHL")
           const error = false
     if(!error) {
       resolve()
     }else{
       reject()
     }
        })
       
  }


   const logout = () => {
       Swal.fire({
           title: 'Are you sure?',
           text: "Do you want to logout?",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Yes!'
         }).then((result) => {
           if (result.isConfirmed) {
            removeToken()
            .then(redirectHandler().then(window.location.reload()))
            .catch(error => console.log(error))
               
           }
         })
       
   }

    return(
        <div className='header' >
            {/* <FaHome  className='home-direct' size={50} onClick={() => {navigate(`/`)}} /> */}
            <section className="logo-head">
                <img src={logoPict} />
            </section>

            <section className="nav-head">

                {/* <div className="full-datetime" >
                {dayOfWeek}, {dayOfMonth} {month} {year}
                </div> */}

                <div className="welcome-text">
                    <text>Welcome, <span>{storage.nama}</span></text>
                    <h5>{dayOfWeek}, {dayOfMonth} {month} {year}</h5>
                </div>

                <div className="full-datetime">
                    {formattedTime}
                </div>

                <div onClick={logout} className="logout-icon">
                    <BiLogOut size={25} color="#ffffff" />
                    <text>Logout</text>
                </div>
            </section>

        </div>
    )
}



export default Header;