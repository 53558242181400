import React, { useEffect, useState,useRef } from "react";
import {
    FaArrowLeft,
    FaPrint
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {baseUrl} from '../../Config/URL'
import {dateFormater} from "../../components/Reuse.jsx"
import './ReportingIpl.css'
import {loadingHandler} from '../../components/Loading'
import Swal from 'sweetalert2';
import {useReactToPrint} from 'react-to-print'
import Sidebar from "../../components/Sidebar.jsx";
import { financeConfig } from "../../components/PageConfig.jsx";





const ReportingIpl = () => {
    const [dateFrom, setDateFrom] = useState("")
    const [forecastIpl, setForecastIpl] = useState(0)
    const [actualIPl, setActualIpl] = useState(0)
    const [forecastInternet, setForecastInternet] = useState(0)
    const [actualInternet, setActualInternet] = useState(0)
    const [forecastIplYtd, setForecastIplYtd] = useState(0)
    const [actualIPlYtd, setActualIplYtd] = useState(0)
    const [forecastInternetYtd, setForecastInternetYtd] = useState(0)
    const [actualInternetYtd, setActualInternetYtd] = useState(0)

    const [dateChoosen, setDateChoosen] = useState(0)

    //Diskon 
    const [actualdiscount_paymentipl, setActualDiscountPaymentIpl] = useState(0)
    const [actualdiscount_paymentinternet, setActualDiscountPaymentInternet] = useState(0)
    const [forecastdiscount_paymentipl, setForecastDiscountPaymentIpl] = useState(0)
    const [forecastdiscount_paymentinternet, setForecastDiscountPaymentInternet] = useState(0)

    //PPN
    const [actualppn_iplnominal, setActualPpnIplNominal] = useState(0)
    const [actualppn_internetnominal, setActualPpnInternetNominal] = useState(0)
    const [forecastppn_iplnominal, setForecastPpnIplNominal] = useState(0)
    const [forecastppn_internetnominal, setForecastPpnInternetNominal] = useState(0)

    //Denda
    const [actualdenda_ipl, setActualDendaIpl] = useState(0)
    const [actualdenda_internet, setActualDendaInternet] = useState(0)
    const [forecastdenda_ipl, setForecastDendaIpl] = useState(0)
    const [forecastdenda_internet, setForecastDendaInternet] = useState(0)

    //Diskon YTD
    const [actualdiscount_paymentiplytd, setActualDiscountPaymentIplYtd] = useState(0)
    const [actualdiscount_paymentinternetytd, setActualDiscountPaymentInternetYtd] = useState(0)
    const [forecastdiscount_paymentiplytd, setForecastDiscountPaymentIplYtd] = useState(0)
    const [forecastdiscount_paymentinternetytd, setForecastDiscountPaymentInternetYtd] = useState(0)

    //PPN YTD
    const [actualppn_iplnominalytd, setActualPpnIplNominalYtd] = useState(0)
    const [actualppn_internetnominalytd, setActualPpnInternetNominalYtd] = useState(0)
    const [forecastppn_iplnominalytd, setForecastPpnIplNominalYtd] = useState(0)
    const [forecastppn_internetnominalytd, setForecastPpnInternetNominalYtd] = useState(0)

    //Denda YTD
    const [actualdenda_iplytd, setActualDendaIplYtd] = useState(0)
    const [actualdenda_internetytd, setActualDendaInternetYtd] = useState(0)
    const [forecastdenda_iplytd, setForecastDendaIplYtd] = useState(0)
    const [forecastdenda_internetytd, setForecastDendaInternetYtd] = useState(0)

    //Actual per unit
    const [actual_unit, setActualUnit] = useState([])

    //Forecast per unit 
    const [forecast_unit, setForecastUnit] = useState([])

    //Forecast per unit ytd
    const [forecast_unitytd, setForecastUnitYtd] = useState([])

    //Forecast per unit ytd
    const [actual_unitytd, setActualUnitYtd] = useState([])

    //All Unit Report
    const [allUnit, setAllUnit] = useState([])

    //Loading Condition
    const [isLoading, setIsLoading] = useState(true)



    const navigate = useNavigate()
    const componentRef = useRef()
    const dateNow = new Date()

    useEffect(() => {
        // getAllUnit()
        // initReport()
        Promise.all([getAllUnit(), initReport()])
        .then(() => {
            setIsLoading(false)
        })
        .catch((error) => {
            // Handle kesalahan jika diperlukan
            console.error('Error:', error);
            setIsLoading(false); // Pastikan isLoading diubah kembali ke false bahkan jika terjadi kesalahan
          });
        
        // ytdReport()
    },[])

    // useEffect(() => {
    //     loadingHandler(isLoading)
    // }, [isLoading])

    const getAllUnit = () => {
        setIsLoading(prevIsloading => !prevIsloading)
        axios.get(baseUrl+"getallunitreport")
        .then(res => {
            //console.log(res.data);
            console.log('then');
            setAllUnit(res.data)
        }).catch(err => {
            console.log(err);
        })
        // .finally(() => {
        //     setIsLoading(prevIsloading => !prevIsloading)
        //     console.log("finnal");
        //     console.log(isLoading);
        // })
    }

    

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Laporan_Pendapatan_Estate_Mazenta_",
        onAfterPrint: () => {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
        }
    })

   

    const actualIplInternet = (e) => {
        axios.post(baseUrl+"reportactual", {
            tgl_jatuhtempo: e
        }).then(res => {
            //console.log(res.data[0]);
            const data = res.data[0]
            setActualInternet(data.actual_internet || 0)
            setActualIpl(data.actual_ipl || 0)
            setActualDiscountPaymentIpl(data.actualdiscount_paymentipl || 0)
            setActualDiscountPaymentInternet(data.actualdiscount_paymentinternet || 0)
            setActualPpnIplNominal(data.actualppn_iplnominal || 0)
            setActualPpnInternetNominal(data.actualppn_internetnominal || 0)
            setActualDendaIpl(data.actualdenda_ipl || 0)
            setActualDendaInternet(data.actualdenda_internet || 0)
        })
    }



    const actualIplInternetperson = (e) => {
        axios.post(baseUrl+"reportactualperson", {
            tgl_jatuhtempo: e
        }).then(res => {
            console.log(res.data);
            const data = res.data
            setActualUnit(res.data)
           
        })
    }

    const forecastIplInternetperson = (e) => {
        axios.post(baseUrl+"reportforecastperson", {
            tgl_jatuhtempo: e
        }).then(res => {
            console.log(res.data);
            const data = res.data
            setForecastUnit(data)
           
        })
    }

    const forecastUnitYtd = (e) => {
        const d = new Date()
        const a = new Date(e)
        //console.log(a.getFullYear()+"-"+"01"+"-"+"01");
        //console.log(e);
        const tgl_first = a.getFullYear()+"-"+"01"+"-"+"01"
        const tgl_now = e
        axios.post(baseUrl+"forecastunitytd", {
            tgl_first,
            tgl_now
        }).then(res => {
            //console.log(res.data);
            const data = res.data
            setForecastUnitYtd(data)
           
        })
    }

    const actualUnitYtd = (e) => {
        const d = new Date()
        const a = new Date(e)
        // //console.log(a.getFullYear()+"-"+"01"+"-"+"01");
        // //console.log(e);
        const tgl_first = a.getFullYear()+"-"+"01"+"-"+"01"
        const tgl_now = e
        axios.post(baseUrl+"actualunitytd", {
            tgl_first,
            tgl_now
        }).then(res => {
            //console.log(res.data);
            const data = res.data
            setActualUnitYtd(data)
           
        })
    }



    const valueUnitHandler = (e, p) => {
        //console.log(p);
        const filtered = p.store.filter(item => item.no_unit === e);
        if (filtered.length > 0) {
            const value = filtered[0][p.params];
            return Number(value)
            // return filtered[0].id
        } else {
            return 0;
        }
        
    }

    

    const forecastIplInternet = (e) => {
        axios.post(baseUrl+"reportforecast", {
            tgl_jatuhtempo: e
        }).then(res => {
            //console.log(res.data[0]);
            const data = res.data[0]
            setForecastInternet(data.forecast_internet || 0)
            setForecastIpl(data.forecast_ipl || 0)

            setForecastDiscountPaymentIpl(data.forecastdiscount_paymentipl || 0)
            setForecastDiscountPaymentInternet(data.forecastdiscount_paymentinternet || 0)

            setForecastPpnIplNominal(data.forecastppn_iplnominal || 0)
            setForecastPpnInternetNominal(data.forecastppn_internetnominal || 0)

            setForecastDendaIpl(data.forecastdenda_ipl || 0)
            setForecastDendaInternet(data.forecastdenda_internet || 0)
            
        })
    }

    const initReport = () => {
        const d = new Date()
        //console.log(d);
        actualIplInternet(d)
        forecastIplInternet(d)
        ytdReport(d)
        setDateChoosen(d)

        actualIplInternetperson(d)
        forecastIplInternetperson(d)
        forecastUnitYtd(d)
        actualUnitYtd(d)
        
    }

    const ytdReport = (e) => {
        const d = new Date()
        const a = new Date(e)
        //console.log(a.getFullYear()+"-"+"01"+"-"+"01");
        //console.log(e);
        const tgl_first = a.getFullYear()+"-"+"01"+"-"+"01"
        const tgl_now = e
        
        
        
        axios.post(baseUrl+"ytdreport", {
            tgl_first,
            tgl_now
        }).then(res => {
            //console.log(res.data[0]);
            const data = res.data[0]
            setForecastInternetYtd(data.forecastytd_internet || 0)
            setForecastIplYtd(data.forecastytd_ipl || 0)

            setForecastDiscountPaymentIplYtd(data.forecastdiscount_paymentiplytd || 0)
            setForecastDiscountPaymentInternetYtd(data.forecastdiscount_paymentinternetytd || 0)

            setForecastPpnIplNominalYtd(data.forecastppn_iplytd || 0)
            setForecastPpnInternetNominalYtd(data.forecastppn_internetytd || 0)

            setForecastDendaIplYtd(data.forecastdenda_iplytd || 0)
            setForecastDendaInternetYtd(data.forecastdenda_internetytd || 0)
        })

        axios.post(baseUrl+"ytdreportactual", {
            tgl_first,
            tgl_now
        }).then(res => {
            //console.log(res.data[0]);
            const data = res.data[0]
            setActualInternetYtd(data.actualtytd_internet || 0)
            setActualIplYtd(data.actualytd_ipl || 0)

            setActualDiscountPaymentIplYtd(data.actualdiscount_paymentiplytd || 0)
            setActualDiscountPaymentInternetYtd(data.actualdiscount_paymentinternetytd || 0)

            setActualPpnIplNominalYtd(data.actualppn_iplytd || 0)
            setActualPpnInternetNominalYtd(data.actualppn_internetytd || 0)

            setActualDendaIplYtd(data.actualdenda_iplytd || 0)
            setActualDendaInternetYtd(data.actualdenda_internetytd || 0)


        })
    }

    const searchDate = (e) => {
        // actualIplInternet(e)
        // forecastIplInternet(e)
        // ytdReport(e)
        // setDateChoosen(e)

        // actualIplInternetperson(e)
        // forecastIplInternetperson(e)
        // forecastUnitYtd(e)
        // actualUnitYtd(e)
        setIsLoading(true)
        Promise.all([actualIplInternet(e),
            forecastIplInternet(e),
            ytdReport(e),
            setDateChoosen(e),
    
            actualIplInternetperson(e),
            forecastIplInternetperson(e),
            forecastUnitYtd(e),
            actualUnitYtd(e),])
            .then(() => {
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const expandCollapse = (e) => {
        const action = document.getElementById(e)

        
            if(action.style.display === "contents"){
                action.style.display = "none"
            }else{
                action.style.display  = "contents"
            }
    

        

        
        // //console.log(action.style.display);
    }

console.log(dateNow);
    
    return(
        <Sidebar menuItems={financeConfig} >
        <main id="unit-main" >
                    <div className="unit-container" >
                        <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/reporting`)}} />
                        <h4>Mazenta Residence</h4>
                        <h2>Laporan IPL & Internet</h2>
                        <section className="search-group" >
    
                            <input type="date" placeholder="Cari No. Unit/Nama" className="search-box" onChange={(e) => (setDateFrom(e.target.value))} />
                            <button onClick={() => {searchDate(dateFrom)}} className="search-button" >Cari</button>
                            <button onClick={initReport} className="search-button" >Reset</button>
                            {/* <button  className="search-button" >Reset</button> */}
    
                        </section>
                        
                        <div className='cetak-button-container' onClick={printHandler} >
                            <FaPrint className='cetak-button' size={30} onClick={printHandler}/>
                            <text>Print</text>
                        </div>
                        <section ref={componentRef} className="list-antrian-report" >
                       
                        <h1>Laporan</h1>
                        <table class="greyGridTable-report">
                            <thead>
                                <tr>
                                <th rowSpan="2">Keterangan</th>
                                <th colSpan={"2"} >Up To {dateFormater(dateChoosen)}</th>
                                <th colSpan={"2"} >YTD {dateFormater(dateChoosen)}</th>
                                <th colSpan={"9"} >Up To {dateFormater(dateChoosen)}</th>
                                </tr>
                                <tr>
                                <th>Forecast</th>
                                <th>Actual</th>
                               
                                <th>Forecast</th>
                                <th>Actual</th>
                                

                                <th>Forecast</th>
                                <th>Actual</th>
                                <th>Net Actual</th>
                                <th>Discount Payment</th>
                                <th>PPN</th>
                                <th>Denda</th>
                                <th>Outstanding</th>
                                <th>Net Outstanding</th>
                                <th>Note</th>


                                </tr>
                            </thead>
                            <tbody>

                                {/* IPL */}
                                <tr className="row-head" >
                                    <td onClick={() => {expandCollapse("ipl-unit")}} className="first-col" style={{fontStyle: "italic", backgroundColor: "rgb(254, 249, 214)"}}>Pendapatan IPL</td>    
                                    {/* up to */}   
                                    <td>{(Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl)).toLocaleString('IN')}</td>       
                                    {/* <td>{Number(forecastIpl-actualIPl).toLocaleString('IN')}</td>  */}
                                          
                                    {/* YTD */}
                                    <td>{(Number(forecastIplYtd)+Number(forecastppn_iplnominalytd)+Number(forecastdenda_iplytd)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualIPlYtd)+Number(actualppn_iplnominalytd)+Number(actualdenda_iplytd)).toLocaleString('IN')}</td>       
                                    {/* <td>{Number(forecastIplYtd-actualIPlYtd).toLocaleString('IN')}</td>        */}

                                    {/* ALL */}
                                    <td>{(Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl)).toLocaleString('IN')}</td>     
                                    <td>{(Number(actualIPl)+Number(actualdiscount_paymentipl)).toLocaleString('IN')}</td>      
                                    <td>{Number(actualdiscount_paymentipl).toLocaleString('IN')}</td>      
                                    <td>{Number(actualppn_iplnominal).toLocaleString('IN')}</td>      
                                    <td>{Number(actualdenda_ipl).toLocaleString('IN')}</td>      
                                    <td>{( (Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl))-(Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl))  ).toLocaleString('IN')}</td>      
                                    <td>{( (Number(forecastIpl)+Number(forecastdiscount_paymentipl)) - (Number(actualIPl)+Number(actualdiscount_paymentipl))  ).toLocaleString('IN')}</td>      
                                    <td className="noted"><input/></td>
                                          
                                </tr>
                                

                                {/* IPL PER UNIT */}
                                <div id="ipl-unit">    
                                    {allUnit.map((item, index) => {
                                        return(
                                            
                                                <tr key={item.no_unit}  className="row-body" >
                                                    <td className="first-col" style={{fontWeight: "normal"}}>{item.no_unit}</td>    
                                                    {/* up to */}   
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_iplperson"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_iplperson"}).toLocaleString('IN')}</td>       
                                                    {/* <td>{Number(forecastIpl-actualIPl).toLocaleString('IN')}</td>  */}
                                                        
                                                    {/* YTD */}
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unitytd, params: "forecast_iplpersonytd"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unitytd, params: "actual_iplpersonytd"}).toLocaleString('IN')}</td>       
                                                    {/* <td>{Number(forecastIplYtd-actualIPlYtd).toLocaleString('IN')}</td>        */}

                                                    {/* ALL */}
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_iplperson"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_iplperson"}).toLocaleString('IN')}</td>     
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_net"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "discountpayment_iplperson"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualppn_iplnominalperson"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualdenda_iplperson"}).toLocaleString('IN')}</td>      
                                                    <td>{( (valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_iplperson"}))-(valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_iplperson"}))  ).toLocaleString('IN')}</td>      
                                                    <td>{( valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_net"}) - valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_net"}) ).toLocaleString('IN')}</td>    
                                                    <td className="noted"><input/></td> 

                                                    {/* <td>{item.nama}</td> */}
                                                        
                                    </tr>
                                            
                                        )
                                    })}

                                </div>
                               

                                
                                {/* Internet */}
                                <tr className="row-head">
                                    <td className="first-col" onClick={() => {expandCollapse("internet-unit")}} style={{borderRight: '1px black solid', fontStyle: "italic", backgroundColor: "rgb(254, 249, 214)"}}>Pendapatan Internet</td>   
                                    {/* up to */}
                                    <td>{(Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)).toLocaleString('IN')}</td>       
                                    {/* <td>{Number(forecastInternet-actualInternet).toLocaleString('IN')}</td>  */}
                                    

                                    {/* YTD */}
                                    <td>{(Number(forecastInternetYtd)+Number(forecastppn_internetnominalytd)+Number(forecastdenda_internetytd)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualInternetYtd)+Number(actualppn_internetnominalytd)+Number(actualdenda_internetytd)).toLocaleString('IN')}</td>       
                                    {/* <td>{Number(forecastInternetYtd-actualInternetYtd).toLocaleString('IN')}</td>        */}

                                    {/* ALL */}
                                    <td>{(Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet)).toLocaleString('IN')}</td>       
                                    <td>{(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)).toLocaleString('IN')}</td>     
                                    <td>{(Number(actualInternet)+Number(actualdiscount_paymentinternet)).toLocaleString('IN')}</td>    
                                    <td>{Number(actualdiscount_paymentinternet).toLocaleString('IN')}</td>    
                                    <td>{Number(actualppn_internetnominal).toLocaleString('IN')}</td>    
                                    <td>{Number(actualdenda_internet).toLocaleString('IN')}</td>    
                                    <td>{( (Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet))-(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)) ).toLocaleString('IN')}</td>   
                                    <td>{( (Number(forecastInternet)+Number(forecastdiscount_paymentinternet)) - (Number(actualInternet)+Number(actualdiscount_paymentinternet))  ).toLocaleString('IN')}</td>      
                                    <td className="noted"><input/></td>
                                    
                                </tr>

                                {/* INTERNET PER UNIT */}
                                <div id="internet-unit">    
                                    {allUnit.map((item, index) => {
                                        return(
                                            
                                                <tr key={item.no_unit}  className="row-body" >
                                                    <td className="first-col" style={{fontWeight: "normal"}}>{item.no_unit}</td>    
                                                    {/* up to */}   
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_internetperson"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_internetperson"}).toLocaleString('IN')}</td>       
                                                    {/* <td>{Number(forecastIpl-actualIPl).toLocaleString('IN')}</td>  */}
                                                        
                                                    {/* YTD */}
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unitytd, params: "forecast_internetpersonytd"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unitytd, params: "actual_internetpersonytd"}).toLocaleString('IN')}</td>       
                                                    {/* <td>{Number(forecastIplYtd-actualIPlYtd).toLocaleString('IN')}</td>        */}

                                                    {/* ALL */}
                                                    <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_internetperson"}).toLocaleString('IN')}</td>       
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_internetperson"}).toLocaleString('IN')}</td>     
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "internet_netactual"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "discountpayment_internetperson"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualppn_internetnominalperson"}).toLocaleString('IN')}</td>      
                                                    <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualdenda_internetperson"}).toLocaleString('IN')}</td>      
                                                    <td>{( (valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_internetperson"}))-(valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_internetperson"}))  ).toLocaleString('IN')}</td>      
                                                    <td>{( valueUnitHandler(item.no_unit, {store: forecast_unit, params: "internet_netforecast"}) - valueUnitHandler(item.no_unit, {store: actual_unit, params: "internet_netactual"}) ).toLocaleString('IN')}</td>     
                                                    <td className="noted"><input/></td>
                                                    {/* <td>{item.nama}</td> */}
                                                        
                                    </tr>
                                            
                                        )
                                    })}

                                </div>

                                

                                {/* Total */}
                                <tr className="row-head">
                                    <td className="first-col">Total Pendapatan</td>

                                    {/* Forecast Upto */}
                                    <td>{( (Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl))+(Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet)) ).toLocaleString('IN')}</td>
                                    {/* Actual Upto */}
                                    <td>{( (Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl))+(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)) ).toLocaleString('IN')}</td>

                                    {/* Forecast YTD */}
                                    <td>{( (Number(forecastIplYtd)+Number(forecastppn_iplnominalytd)+Number(forecastdenda_iplytd))+(Number(forecastInternetYtd)+Number(forecastppn_internetnominalytd)+Number(forecastdenda_internetytd)) ).toLocaleString('IN')}</td>
                                    {/* Actual YTD */}
                                    <td>{( (Number(actualIPlYtd)+Number(actualppn_iplnominalytd)+Number(actualdenda_iplytd))+(Number(actualInternetYtd)+Number(actualppn_internetnominalytd)+Number(actualdenda_internetytd)) ).toLocaleString('IN')}</td>

                                    {/* Forecast All */}
                                    <td>{( (Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl))+(Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet)) ).toLocaleString('IN')}</td>
                                    {/* Actual All */}
                                    <td>{( (Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl))+(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)) ).toLocaleString('IN')}</td>
                                    {/* Net Actual All */}
                                    <td>{( (Number(actualIPl)+Number(actualdiscount_paymentipl))+(Number(actualInternet)+Number(actualdiscount_paymentinternet)) ).toLocaleString('IN')}</td>
                                    {/* Discount All */}
                                    <td>{(Number(actualdiscount_paymentipl) + Number(actualdiscount_paymentinternet)).toLocaleString('IN')}</td>
                                    {/* PPN All */}
                                    <td>{(Number(actualppn_iplnominal) + Number(actualppn_internetnominal)).toLocaleString('IN')}</td>
                                    {/* Denda All */}
                                    <td>{(Number(actualdenda_ipl) + Number(actualdenda_internet)).toLocaleString('IN')}</td>
                                    {/* Outstanding All */}
                                    <td>{(( (Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl))-(Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl))  ) + ( (Number(forecastInternet)+Number(forecastppn_internetnominal)+Number(forecastdenda_internet))-(Number(actualInternet)+Number(actualppn_internetnominal)+Number(actualdenda_internet)) )).toLocaleString('IN')}</td>
                                    {/* Net Outstanding All */}
                                    <td>{(( ( (Number(forecastIpl)+Number(forecastdiscount_paymentipl)) - (Number(actualIPl)+Number(actualdiscount_paymentipl))  )  ) + ( (Number(forecastInternet)+Number(forecastdiscount_paymentinternet)) - (Number(actualInternet)+Number(actualdiscount_paymentinternet))  )).toLocaleString('IN')}</td>
                                    <td className="noted"><input/></td>
                                </tr>

                                
    
                                
        {/* <h1>{forecastppn_iplnominalytd}</h1> */}
                                
                            </tbody>
                        </table>


                        
    
    
    
                        
    
    
                        </section>
                    </div>
                </main>
                </Sidebar>
    )

}


export default ReportingIpl;


// {allUnit.map((item, index) => {
//     return(
//         <>
//             <tr>
//                 <td className="first-col" style={{fontWeight: "normal"}}>{item.no_unit}</td>    
//                 {/* up to */}   
//                 <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_iplperson"}).toLocaleString('IN')}</td>       
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_iplperson"}).toLocaleString('IN')}</td>       
//                 {/* <td>{Number(forecastIpl-actualIPl).toLocaleString('IN')}</td>  */}
                    
//                 {/* YTD */}
//                 <td>{valueUnitHandler(item.no_unit, {store: forecast_unitytd, params: "forecast_iplpersonytd"}).toLocaleString('IN')}</td>       
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unitytd, params: "actual_iplpersonytd"}).toLocaleString('IN')}</td>       
//                 {/* <td>{Number(forecastIplYtd-actualIPlYtd).toLocaleString('IN')}</td>        */}

//                 {/* ALL */}
//                 <td>{valueUnitHandler(item.no_unit, {store: forecast_unit, params: "forecast_iplperson"}).toLocaleString('IN')}</td>       
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actual_iplperson"}).toLocaleString('IN')}</td>     
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "netactual_iplperson"}).toLocaleString('IN')}</td>      
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "discountpayment_iplperson"}).toLocaleString('IN')}</td>      
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualppn_iplnominalperson"}).toLocaleString('IN')}</td>      
//                 <td>{valueUnitHandler(item.no_unit, {store: actual_unit, params: "actualdenda_iplperson"}).toLocaleString('IN')}</td>      
//                 <td>{( (Number(forecastIpl)+Number(forecastppn_iplnominal)+Number(forecastdenda_ipl))-(Number(actualIPl)+Number(actualppn_iplnominal)+Number(actualdenda_ipl))  ).toLocaleString('IN')}</td>      
//                 <td>{( (Number(forecastIpl)+Number(forecastdiscount_paymentipl)) - (Number(actualIPl)+Number(actualdiscount_paymentipl))  ).toLocaleString('IN')}</td>      

//                 {/* <td>{item.nama}</td> */}
                    
// </tr>
//         </>
//     )
// })}