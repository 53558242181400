import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {baseUrl} from '../../Config/URL'
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";



const DaftarIPL = () => {
    const [dataHasBast, setDataHasBast] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        getDataHasBast()
    },[])

    const navigate = useNavigate()

    const getDataHasBast = () => {
        axios.get(baseUrl+"getdatahasbast")
        .then(res => {
            console.log(res.data);
            setDataHasBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const searchDataHasBast = () => {
        axios.post(baseUrl+"searchdatahasbast",{
            nama:search
        })
        .then(res => {
            console.log(res.data);
            setDataHasBast(res.data)
        })
    }

    return(
        <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Registrasi</h2>
                    <section className="search-group" >

                        <input onChange={(e) => {setSearch(e.target.value)}} type="search" placeholder="Cari No. Unit/Nama" className="search-box" />
                        <button onClick={searchDataHasBast} className="search-button" >Cari</button>
                        <button onClick={getDataHasBast} className="search-button" >Reset</button>

                    </section>  
                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th className="no">No.</th>
                            <th>Customer</th>
                            <th>No. Unit</th>
                            <th>Tipe Bangunan</th>
                            <th>Alamat Email</th>
                            <th>No. Hp</th>
                            <th>No. BAST</th>
                            <th>Tanggal BAST</th>
                            <th>Status </th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataHasBast.map((item, index) => {
                                return(
                                    <>
                                        <tr onClick={()=>{navigate("/formipl",{state:item})}}>
                                            <td className="no">{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.alamat_email}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.no_bast}</td>
                                            <td>{item.date_full}</td>
                                            <td>{item.status_bast}</td>
                                        </tr>
                                    </>
                                )
                            })}

                            
                        </tbody>
                    </table>
                    {dataHasBast.length === 0 && <h3 className="no-data">Belum Ada Data</h3>}



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Input Rate Untuk</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Cluster: </label>
                    <select >
                      <option>Mazenta 1</option>
                      <option>Mazenta 2</option>
                    </select>
                    <label>Unit: </label>
                    <select >
                      <option>001</option>
                      <option>002</option>
                    </select>
                    <div className = "form-button" > 
                    <button className = "profile-save btn "  >Simpan</button>
                    <button className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>

    )

}

export default DaftarIPL