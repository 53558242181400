import React, { useEffect, useState } from "react";
import './Setting.css'
import { useNavigate } from "react-router-dom";
import {Denied} from '../../components/Constuct'
import Sidebar from "../../components/Sidebar";
import { settingConfig } from "../../components/PageConfig";

const Setting = () => {
    const [storage, setStorage] = useState({})

    

    const navigate = useNavigate()
    useEffect(() => {
        const token = sessionStorage.getItem('TokenCHL')
        setStorage(JSON.parse(token))
    },[])
    
    console.log(storage);

    if(storage.id_role === 1 || storage.id_role === 2){
        return(
            <Sidebar menuItems={settingConfig}>
                <main id='sales-container' >
                
                <section className='sales-group-card' >
                

                    <h1>Setting</h1>

                </section>
            
            
        </main>
            {/* <main id="setting">
                <h1>setting</h1>
    
                <section className="set-all" id="set-user">
    
                <h3>USER</h3>
                
                <ul className="list-set">
                    <li onClick={() => {navigate('/newuser', {state: {condition: "newuser"}})}} >Add New User</li>
                    <li onClick={() => {navigate('/newuser', {state: {condition: "edituser"}})}}>Edit User</li>
                </ul>
    
                </section>
    
              

                <section className="set-all" id="set-user">
    
                <h3>Promo</h3>
                
                <ul className="list-set">
                    <li onClick={() => {navigate('/newpromo', {state: {condition: "newpromoipl"}})}} >Add New Promo IPL</li>
                    <li onClick={() => {navigate('/newpromo', {state: {condition: "newpromointernet"}})}} >Add New Promo Internet</li>
                    <li >Edit Promo</li>
                </ul>
    
                </section>
    
            </main> */}
            </Sidebar>
        )
    }else if(storage.id_role === 3){
        return(
            <Sidebar menuItems={settingConfig}>
            <main id="setting">
                <h1>setting</h1>
    
                <Denied/>
    
            </main>
            </Sidebar>
        )
    }

    

}

export default Setting;