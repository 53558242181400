import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashbosrd/Dashboard';
import Invoice from './pages/Estate/Invoice.jsx';
import Comment from './pages/Comment.jsx';
import Product from './pages/Product.jsx';
import ProductList from './pages/ProductList.jsx';
import NewCustomer from './pages/Sales/NewCustomer';
import Sales from './pages/Sales/Sales';
import Unit from './pages/Sales/Unit';
import Login from './pages/Login/Login';
import DaftarIPL from './pages/Estate/DaftarIPL.jsx';
import FormDataIPL from './pages/Estate/FormDaftarIPL.jsx';
import NewInvoice from './pages/Estate/NewInvoice.jsx';
import Bast from './pages/Sales/Bast';
import IPLList from './pages/Estate/IPLList.jsx';
import CetakIPL from './pages/Estate/CetakIPL.jsx';
import DetailCetak from './pages/Estate/DetailCetak.jsx';
import Unpaid from './pages/Estate/Unpaid.jsx';
import Kwitansi from './pages/Estate/Kwitansi.jsx';
import CetakKwitansi from './pages/Estate/CetakKwitansi.jsx';
import AllCustomer from './pages/Sales/AllCustomer';
import Setting from './pages/Setting/Setting';
import NewUser from './pages/Setting/NewUser';
import SettingRate from './pages/Setting/SettingRate';
import EditCustomer from './pages/Setting/EditCustomer';
import EditDetailCustomer from './pages/Setting/EditDetailCustomer';
import Reporting from './pages/Reporting/Reporting';
import ReportingIpl from './pages/Reporting/ReportingIpl';
import YearReporting from './pages/Reporting/YearReporting';
import DeleteCustomer from './pages/Setting/DeleteCustomer';
import NewPromo from './pages/Setting/NewPromo';
import EditCustomerRate from './pages/Setting/EditCustomerRate';
import EditDetailCustomerRate from './pages/Setting/EditDetailCustomerRate';
import Home from './pages/Home.jsx';
import Header from './components/Header.jsx';
import MasterRate from './pages/Setting/MasterRate.jsx';
import UploadBast from './pages/Estate/UploadBast.jsx';
import UploadInvoice from './pages/Estate/UploadInvoice.jsx';
import UploadCustomer from './pages/Sales/UploadCustomer.jsx';
import EditInternetRate from './pages/Setting/EditInternetRate.jsx';
import NewPromoInternet from './pages/Setting/NewPromoInternet.jsx';
import EditUser from './pages/Setting/EditUser.jsx';

const App = () => {


  // const logout = () => {
  //   return(
  //     <BrowserRouter>
      
  //       <Routes>
          
  //         <Route path="/" element={<Login />} />
        
  //       </Routes>
      
  //   </BrowserRouter>
  //   )
  // }

  // const login = () => {

  //   return(

  //     <BrowserRouter>
  //     <Sidebar>
  //       <Routes>
  //         <Route path="/" element={<Dashboard />} />
  //         <Route path="/dashboard" element={<Dashboard />} />
  //         <Route path="/unit" element={<Unit />} />
  //         <Route path="/sales" element={<Sales/>} />
  //         <Route path="/newcustomer" element={<NewCustomer />} />
  //         <Route path="/comment" element={<Comment />} />
  //         <Route path="/invoice" element={<Invoice />} />
  //         <Route path="/product" element={<Product />} />
  //         <Route path="/productList" element={<ProductList />} />
  //       </Routes>
  //     </Sidebar>
  //   </BrowserRouter>

  //   )

  // }

  if (window.sessionStorage.getItem('TokenCHL') === null) {
    return(
      <BrowserRouter>
      
        <Routes>
          
          <Route path="/" element={<Login />} />
        
        </Routes>
      
    </BrowserRouter>
    )
    
  }else{
    return(

      <BrowserRouter>
      <Header/>
        <Routes>
          <Route path='/' element={<Home/>} />


          <Route path="/sales" element={<Sales/>} />
          <Route path="/newcustomer" element={<NewCustomer />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/editcustomer" element={<EditCustomer />} />
          <Route path="/editdetailcustomer" element={<EditDetailCustomer />} />
          <Route path="/deletecustomer" element={<DeleteCustomer />} />
          <Route path="/uploadcustomer" element={<UploadCustomer />} />


          
          <Route path="/bast" element={<Bast />} />
          <Route path="/uploadbast" element={<UploadBast />} />
          <Route path="/uploadinvoice" element={<UploadInvoice />} />
          <Route path="/daftaripl" element={<DaftarIPL />} />
          <Route path="/formipl" element={<FormDataIPL />} />
          <Route path="/newinvoice" element={<NewInvoice />} />
          <Route path="/cetakipl" element={<CetakIPL />} />
          <Route path="/detailcetak" element={<DetailCetak />} />
          <Route path="/unpaid" element={<Unpaid />} />
          <Route path="/kwitansi" element={<Kwitansi />} />
          <Route path="/cetakkwitansi" element={<CetakKwitansi />} />
          <Route path="/ipllist" element={<IPLList />} />
          <Route path="/setting" element={<Setting />} />

          <Route path="/allcustomer" element={<AllCustomer />} />
          
          <Route path="/newuser" element={<NewUser />} />
          <Route path="/edituser" element={<EditUser />} />
          <Route path="/editrate" element={<SettingRate />} />
          <Route path="/editinternetrate" element={<EditInternetRate />} />
          <Route path="/editcustomer" element={<EditCustomer />} />
          
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/reportingipl" element={<ReportingIpl />} />
          <Route path="/yearreporting" element={<YearReporting />} />
          <Route path="/deletecustomer" element={<DeleteCustomer />} />
          <Route path="/newpromo" element={<NewPromo />} />
          <Route path="/newpromointernet" element={<NewPromoInternet />} />
          <Route path="/editcustomerrate" element={<EditCustomerRate />} />
          <Route path="/editdetailcustomerrate" element={<EditDetailCustomerRate />} />
          <Route path="/masterrate" element={<MasterRate />} />
        </Routes>

      {/* <Sidebar>
        <Routes>
          <Route path="/sales" element={<Sales/>} />
        </Routes>
      </Sidebar> */}
      
      
      {/* <Sidebar>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/sales" element={<Sales/>} />
          <Route path="/newcustomer" element={<NewCustomer />} />
          <Route path="/comment" element={<Comment />} />
          <Route path="/estate" element={<Invoice />} />
          <Route path="/daftaripl" element={<DaftarIPL />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productList" element={<ProductList />} />
          <Route path="/formipl" element={<FormDataIPL />} />
          <Route path="/newinvoice" element={<NewInvoice />} />
          <Route path="/cetakipl" element={<CetakIPL />} />
          <Route path="/unpaid" element={<Unpaid />} />
          <Route path="/bast" element={<Bast />} />
          <Route path="/ipllist" element={<IPLList />} />
          <Route path="/detailcetak" element={<DetailCetak />} />
          <Route path="/kwitansi" element={<Kwitansi />} />
          <Route path="/cetakkwitansi" element={<CetakKwitansi />} />
          <Route path="/allcustomer" element={<AllCustomer />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/newuser" element={<NewUser />} />
          <Route path="/editrate" element={<SettingRate />} />
          <Route path="/editcustomer" element={<EditCustomer />} />
          <Route path="/editdetailcustomer" element={<EditDetailCustomer />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/reportingipl" element={<ReportingIpl />} />
          <Route path="/yearreporting" element={<YearReporting />} />
          <Route path="/deletecustomer" element={<DeleteCustomer />} />
          <Route path="/newpromo" element={<NewPromo />} />
          <Route path="/editcustomerrate" element={<EditCustomerRate />} />
          <Route path="/editdetailcustomerrate" element={<EditDetailCustomerRate />} />
        </Routes>
      </Sidebar> */}
    </BrowserRouter>

    )
  }


  
};

export default App;