import React from "react";

export function dateFormater (m) {
    
    const d = new Date(m)
    const formater = new Intl.DateTimeFormat('id',{month: "short", day: "numeric", year: "numeric"})
    let value = formater.format(d)

    return value
}

export function dateFormater2 (m){
    const inputDate = m;
const parsedDate = new Date(inputDate.replace(/(\d+)(st|nd|rd|th)/, '$1'));

const year = parsedDate.getFullYear();
const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
const day = String(parsedDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;
return formattedDate
}

export function noData(){
    return(
        <h3>Belum Ada Data</h3>
    )
}

