import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import ExcelJS from 'exceljs';
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";


const UploadBast = () => {

    const [uploadFile, setUploadFile] = useState("")

    const descTable = [
        {
            name:"No. BAST",
            keterangan: "Nomor BAST",
            nilaiKosong: "Tidak Boleh Kosong",
            contohInput : "xx-BAST-SBC-xx-xxxx"
        },
        {
            name:"Tanggal BAST",
            keterangan:"Tanggal BAST",
            nilaiKosong: "Tidak Boleh Kosong",
            contohInput : "12/12/1998"
        },
        {
            name:"Note",
            keterangan:"Catatan",
            nilaiKosong:"-",
            contohInput : "Serah Terima Selesai"
        },
        {
            name:"Status BAST",
            keterangan:"Status BAST",
            nilaiKosong:"Tidak Boleh Kosong",
            contohInput : "BAST/BAST PP/STO"
        },
        
]

    const navigate = useNavigate()

    const downloadTemplate = () => {

        //axios.get(baseUrl+"templatedownloadbast")
        axios({
            url: baseUrl+"templatedownloadbast",
            method: 'GET',
            responseType: 'blob',
          })
        .then(res => {
            console.log(res);
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Template BAST.xlsx'; // Sesuaikan dengan nama file yang sesuai.
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })

    }

    const uploadBast = () => {
        if(uploadFile === ""){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text:"Tidak ada file yang dipilih!!",
                allowOutsideClick: false
              })
        }else{
            const formData = new FormData()
            formData.append('excelFile', uploadFile)
            const fileReader = new FileReader();

            fileReader.onload = async () => {
                const buffer = fileReader.result;
                const workbook = new ExcelJS.Workbook();
                const readExcel = await workbook.xlsx.load(buffer);
        
                const worksheet = readExcel.getWorksheet(1); // Ambil worksheet pertama
        
                // Ambil baris pertama dari worksheet
                const firstRow = worksheet.getRow(1).values;
                
                // Data sekarang adalah array yang berisi baris pertama dari worksheet
                console.log(firstRow);

                if(firstRow.length === 6){
                    axios.post(baseUrl+"uploadbast", formData)
                    .then(res => {
                        console.log(res.data);
                        Swal.fire({
                            title: 'Success',
                            text: res.data.msg,
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false
                        })
                    }).catch(err => {
                        console.log(err.message);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.response.data.msg,
                            allowOutsideClick: false
                        })
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Format Template Salah!",
                        allowOutsideClick: false
                    })
                }
            };
      
            fileReader.readAsArrayBuffer(uploadFile);

            
        }
    }

    console.log(uploadFile);

    return(
        <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                        <h2>Upload BAST</h2>
                    <section className="upload-section" >
                        <button onClick={downloadTemplate}>Download Template</button>
                        <button onClick={uploadBast}>Upload</button>
                        <input onChange={(e) => setUploadFile(e.target.files[0])} type="file" accept=".xlsx"/>
                    </section>

                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr className="no">
                            <th>No.</th>
                            <th>Nama</th>
                            <th>Keterangan</th>
                            <th>Nilai Kosong</th>
                            <th>Contoh Input</th>
                            </tr>
                        </thead>
                        <tbody>

                        {descTable.map((item, i) => {
                            return(
                                <tr>
                                    <td className="no">{i+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.keterangan}</td>
                                    <td>{item.nilaiKosong}</td>
                                    <td>{item.contohInput}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>


                    </section>

                    
                </div>
            </main>
            </Sidebar>
    )

}

export default UploadBast;