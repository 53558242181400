import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import ExcelJS from 'exceljs';
import Sidebar from "../../components/Sidebar";
import { estateConfig } from "../../components/PageConfig";


const Bast = () => {
    const [bastNo, setBastNo] = useState("")
    const [bastDate, setBastDate] = useState("")
    const [statusBast, setStatusBast] = useState("")
    const [noKontrak, setNoKontrak] = useState("")
    const [keterangan, setKeterangan] = useState("")
    const [dataNoBast, setDataNoBast] = useState([])
    const [dataStatusBast, setDataStatusBast] = useState([])
    const [custId, setCustId] = useState("")
    const [custName, setCustName] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")

    const [uploadFile, setUploadFile] = useState("")

    const navigate = useNavigate()

   

    useEffect(() => {
        loading2(isLoading)
    })

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        setCustId(e.id_customer)
        setCustName(e.nama)
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setBastDate("")
        setBastNo("")
        setStatusBast("")
        setNoKontrak("")
        setKeterangan("")
      }

    const getStatusBast = () => {
        axios.get(baseUrl+"getallstatusbast")
        .then(res => {
            console.log(res.data);
            setDataStatusBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }
    
    const getDataNoBast = () => {
        axios.get(baseUrl+"getdatanobast")
        .then(res => {
            console.log(res.data);
            setDataNoBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const searchNoBast = () => {
        axios.post(baseUrl+"searchdatanobast",{
            nama:search
        })
        .then(res => {
            console.log(res.data);
            setDataNoBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const saveDataBast = () => {
        setIsLoading(true)
        console.log(statusBast);
        console.log(keterangan);
        axios.post(baseUrl+"savebast",{
            no_bast: bastNo,
            tgl_bast: bastDate,
            id_customer: custId,
            status_bast: statusBast,
            note: keterangan,
        }).then(res => {
            setIsLoading(false)
            console.log(res);

            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    getDataNoBast()
                    closeModalHandler()
                    setIsLoading(false)
                }
              })
        }).catch(err => {
            setIsLoading(false)
            console.log(err);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    setIsLoading(false)
                }
              })
        })
    }

    const loading2 = () => {
        if(isLoading){
            return(
                Swal.fire({
                    icon: "info",
                    title: "Loading",
                    text: "Please Wait",
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowEnterKey: false,
                    allowOutsideClick: false

                    
                })
            )
        }else{return null}
    }

    const downloadTemplate = () => {

        //axios.get(baseUrl+"templatedownloadbast")
        axios({
            url: baseUrl+"templatedownloadbast",
            method: 'GET',
            responseType: 'blob',
          })
        .then(res => {
            console.log(res);
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Template BAST.xlsx'; // Sesuaikan dengan nama file yang sesuai.
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })

    }

    const uploadBast = () => {
        if(uploadFile === ""){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text:"Tidak ada file yang dipilih!!",
                allowOutsideClick: false
              })
        }else{
            const formData = new FormData()
            formData.append('excelFile', uploadFile)
            const fileReader = new FileReader();

            fileReader.onload = async () => {
                const buffer = fileReader.result;
                const workbook = new ExcelJS.Workbook();
                const readExcel = await workbook.xlsx.load(buffer);
        
                const worksheet = readExcel.getWorksheet(1); // Ambil worksheet pertama
        
                // Ambil baris pertama dari worksheet
                const firstRow = worksheet.getRow(1).values;
                
                // Data sekarang adalah array yang berisi baris pertama dari worksheet
                console.log(firstRow);

                if(firstRow.length === 6){
                    axios.post(baseUrl+"uploadbast", formData)
                    .then(res => {
                        console.log(res.data);
                        Swal.fire({
                            title: 'Success',
                            text: res.data.msg,
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false
                        })
                    }).catch(err => {
                        console.log(err);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.response.data.msg,
                            allowOutsideClick: false
                        })
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Format Template Salah!",
                        allowOutsideClick: false
                    })
                }
            };
      
            fileReader.readAsArrayBuffer(uploadFile);

            // axios.post(baseUrl+"uploadbast", formData)
            // .then(res => {
            //     console.log(res.data);
            //     Swal.fire({
            //         title: 'Success',
            //         text: res.data.msg,
            //         icon: 'success',
            //         showConfirmButton: true,
            //         confirmButtonColor: '#3085d6',
            //         confirmButtonText: 'Ok',
            //         allowOutsideClick: false
            //       })
            // }).catch(err => {
            //     console.log(err);
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: err.response.data.msg,
            //         allowOutsideClick: false
            //       })
            // })
        }
    }

    console.log(uploadFile);

    return(
        <Sidebar menuItems={estateConfig}>
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/estate`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Berita Acara Serah Terima</h2>
                    <section className="search-group" >

                        <input onChange={(e) => {setSearch(e.target.value)}} type="search" placeholder="Cari No. Unit/Nama" className="search-box" />
                        <button onClick={searchNoBast} className="search-button" >Cari</button>
                        <button onClick={getDataNoBast} className="search-button" >Reset</button>

                    </section>

                    {/* <section className="upload-section" >
                        <button onClick={downloadTemplate}>Download Template</button>
                        <button onClick={uploadBast}>Upload</button>
                        <input onChange={(e) => setUploadFile(e.target.files[0])} type="file" accept=".xlsx"/>
                    </section> */}

                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr className="no">
                            <th>No.</th>
                            <th>Customer</th>
                            <th>Tgl. Kontrak</th>
                            <th>No. Kontrak</th>
                            <th>Unit</th>
                            <th>Tipe </th>
                            <th>Luas Tanah</th>
                            <th>Luas Bangunan</th>
                            <th>No. HP</th>
                            <th>Alamat Email</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataNoBast.map((item, index) => {
                                return(
                                    <>
                                       <tr onClick={() => {openModalHandler(item)}}>
                                            <td className="no">{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{ item.tgl_kontrak !== null ? item.tgl_kontrak.replace(/(\d+)(st|nd|rd|th)/, "$1") : "-"}</td>
                                            <td>{item.no_kontrak}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.luas_tanah}</td>
                                            <td>{item.luas_bangunan}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.alamat_email}</td>
                                        </tr> 
                                    </>
                                )
                            })}

                            {/* <tr onClick={() => {openModalHandler("1")}}>
                                <td>1</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr> */}

                        </tbody>
                    </table>
                    {dataNoBast.length === 0 && <h3 className="no-data">Belum Ada Data</h3>}


                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail BAST Untuk: {custName}</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Status Bast :  </label>
                    <select value={statusBast} onChange={(e) => {setStatusBast(e.target.value)}} >
                        <option value="">--Select Status--</option>
                        {dataStatusBast.map((item, index) => {
                            return(
                                <>
                                    <option value={item.status_bast} >{item.status_bast}</option>
                                </>
                            )
                        })}
                    </select>
                    {/* <label>No. Kontrak :  </label>
                    <input value={noKontrak} onChange={(e) => {setNoKontrak(e.target.value)}} type="text"/> */}
                    <label>No. Bast/Bast PP :  </label>
                    <input type="text" value={bastNo} onChange={(e) => {setBastNo(e.target.value)}}   />
                    <label>Tgl Bast :  </label>
                    <input type="date" value={bastDate} onChange={(e) => {setBastDate(e.target.value)}}   />
                    <label>Keterangan : (Opsional) </label>
                    <textarea value={keterangan} onChange={(e) => {setKeterangan(e.target.value)}}  />
                    {/* <label>Kuantitas: </label>
                    <input type="number"  />
                    <label>Denda: </label>
                    <input type="number"  />
                    <label>Paket: </label>
                    <input type="text"/> */}
                    <div className = "form-button" > 
                    <button className = "profile-save btn" onClick={saveDataBast} >Simpan</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Keluar</button>
                    </div>
                    {/* <button className="cancel-btn" >Pembatalan</button> */}
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
            </Sidebar>
    )

}

export default Bast;