import React from "react";
import { FaHouseUser, FaUserPlus, FaHandshake, FaClipboardList, FaDollarSign } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {BsGraphUp} from "react-icons/bs"
import {HiOfficeBuilding} from "react-icons/hi"
import {AiOutlineSetting} from "react-icons/ai"


import './Home.css'


const Home = () => {

    return(
        <main id='home-container' >
                
                {/* <h1 className='title-page' >Sales</h1> */}
                <section className='home-group-card' >

                    <Link to="/sales" className='card-link' >
                        <BsGraphUp size={100} color='#ffffff'/>
                        <h3>Sales</h3>
                    </Link>
                    <Link to="/estate" className='card-link' >
                        <HiOfficeBuilding size={100} color='#ffffff'/>
                        <h3>Estate</h3>
                    </Link>
                    <Link to="/reporting" className='card-link' >
                        <FaDollarSign size={100} color='#ffffff'/>
                        <h3>Finance</h3>
                    </Link>
                    <Link to="/setting" className='card-link' >
                        <AiOutlineSetting size={100} color='#ffffff'/>
                        <h3>Setting</h3>
                    </Link>
                    {/* <Link to="/bast" className='card-link' >
                        <FaHandshake size={100} color='#ffffff'/>
                        <h3>Bast</h3>
                    </Link> */}
                    {/* <Link to="/allcustomer" className='card-link' >
                        <FaClipboardList size={90} color='#ffffff'/>
                        <h3>All Customer</h3>
                    </Link> */}

                </section>
            </main>
    )

}

export default Home;