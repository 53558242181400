import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from 'sweetalert2';
import Sidebar from "../../components/Sidebar";
import { salesConfig } from "../../components/PageConfig";

const DeleteCustomer = () => {
    const [dataAllCustomer, setDataAllCustomer] = useState([])
    const [choseCust, setChoseCust] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        getAllCustomer()
    },[])


    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        setChoseCust(e)
        console.log(e);
        
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setChoseCust("")
        
      }
    
    const getAllCustomer = () => {
        axios.get(baseUrl+"getalldatacustomer")
        .then(res => {
            console.log(res.data);
            setDataAllCustomer(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteHandler = (e) => {
         console.log(e);
         axios.patch(baseUrl+"deletecustomer", {
            data: {
                id: e.id_customer,
                no_unit: e.no_unit,
            },
        }).then(res => {
            console.log(res.data);
            closeModalHandler()
            Swal.fire({
                title: 'Success',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
            getAllCustomer()
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
            })
        })
    }


    return(
        <Sidebar menuItems={salesConfig} >
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <h4>Mazenta Residence</h4>
                    <h2>Delete Customer</h2>
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    
                    <section className="list-antrian" >
                    
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>No.Hp</th>
                            <th>Email</th>
                            <th>Unit</th>
                            <th>Tipe</th>
                            <th>Luas Tanah</th>
                            <th>Luas Bangunan</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataAllCustomer.map((item, index) => {
                                return(
                                    <>
                                       <tr onClick={() => {openModalHandler(item)}} >
                                            <td>{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.email}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.luas_tanah} m<sup>2</sup></td>
                                            <td>{item.luas_bangunan} m<sup>2</sup></td>
                                        </tr> 
                                    </>
                                )
                            })}

                            

                        </tbody>
                    </table>


                    <div className = "profile-modal" id = "profile-modal" >
                        <div style={{backgroundColor: "InfoBackground"}} className = "profile-modalContent" >
                            {/* <h1>Delete Customer</h1> */}
                            <div className = "profile-editForm" >
                            <h1>Anda Yakin Ingin Menghapus Data Customer Dengan Nama:</h1>
                            <h1>{choseCust.nama}</h1>
                            <h3>Note: Data yang telah terhapus tidak akan dapat di pulihkan kembali!</h3>
                            <div className = "form-button" > 
                                <button onClick={() => {deleteHandler(choseCust)}} className = "profile-save btn "   >Delete</button>
                                <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                            </div>
                            </div>
                            
                            
                        </div>
                        
                    </div>
                    



                    </section>
                </div>
            </main>
            </Sidebar>
    )


}

export default DeleteCustomer;