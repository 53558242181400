import { 
    FaEdit,
    FaPrint,
    FaHouseUser,
    FaUserPlus, 
    FaHandshake, 
    FaClipboardList, 
    FaRegEdit, 
    FaFileInvoiceDollar, 
    FaFileExcel, 
    FaReceipt,
    FaFileUpload,
    FaUser,
    FaFileInvoice,
    FaCommentDollar,
    FaDollarSign,
} from 'react-icons/fa';
import {BsGraphUp, BsTable} from "react-icons/bs"
import {MdAccessibilityNew, MdAdminPanelSettings, MdDelete,MdFactCheck,MdInput,MdOtherHouses, MdOutlinePayment, MdPriceChange, MdSignalCellular4Bar, MdSignalWifi4Bar} from "react-icons/md"
import {HiOfficeBuilding, HiDocumentAdd, HiOutlineDocumentReport} from "react-icons/hi"
import {AiOutlineSetting} from "react-icons/ai"


// import {BsFileEarmarkCheck, BsFileEarmarkBreak} from "react-icons/bs"




export const salesConfig = {
    title: {
        text: "Sales",
        icon: <BsGraphUp size={50} />,
        color: `rgb(255, 102, 0)`
    },
    menu: [
        {
            name: "Customer",
            icon: <FaUser/>,
            subMenu: [
                {
                    path:"/newcustomer",
                    name:"Pendaftaran",
                    icon:<FaUserPlus/>
                },
                {
                    path:"/editcustomer",
                    name:"Master",
                    icon:<FaRegEdit/>
                },
                {
                    path:"/uploadcustomer",
                    name:"Upload",
                    icon:<FaFileUpload/>
                },
            ]
        },

        {
            name: "Unit",
            icon: <MdOtherHouses/>,
            subMenu: [
                {
                    path:"/unit",
                    name:"Pilih Unit",
                    icon:<FaHouseUser/>
                },
            ]
        },

        
        
        
        // {
        //     path:"/deletecustomer",
        //     name:"Delete",
        //     icon:<MdDelete/>
        // },
        
       
    ]
}

export const estateConfig = {
    title: {
        text: "Estate",
        icon: <HiOfficeBuilding size={50} />,
        color: `rgb(163, 199, 2)`
    },

    menu: [
        {
            name: "BAST",
            icon: <MdInput/>,
            subMenu: [
                {
                    path:"/Bast",
                    name:"Input",
                    icon:<FaHandshake/>
                },
                {
                    path:"/uploadbast",
                    name:"Upload",
                    icon:<FaFileUpload/>
                },
            ]
        },
        {
            name: "Invoice",
            icon: <FaFileInvoice/>,
            subMenu: [
                {
                    path:"/daftaripl",
                    name:"Registrasi",
                    icon:<HiDocumentAdd/>
                },
                {
                    path:"/newinvoice",
                    name:"Tagihan Invoice",
                    icon:<FaFileInvoiceDollar/>
                },
                {
                    path:"/cetakipl",
                    name:"Print Invoice",
                    icon:<FaPrint/>
                },
                // {
                //     path:"/unpaid",
                //     name:"Payment",
                //     icon:<MdOutlinePayment/>
                // },
                {
                    path:"/kwitansi",
                    name:"Kwitansi",
                    icon:<FaReceipt/>
                },
                {
                    path:"/uploadinvoice",
                    name:"Upload",
                    icon:<FaFileUpload/>
                },
            ]
        },



       
    ],

    // menu: [
    //     {
    //         path:"/Bast",
    //         name:"BAST",
    //         icon:<FaHandshake/>
    //     },
    //     {
    //         path:"/daftaripl",
    //         name:"Input Rate",
    //         icon:<HiDocumentAdd/>
    //     },
    //     {
    //         path:"/newinvoice",
    //         name:"Buat Tagihan",
    //         icon:<FaFileInvoiceDollar/>
    //     },
    //     {
    //         path:"/cetakipl",
    //         name:"Cetak Tagihan",
    //         icon:<FaPrint/>
    //     },
    //     {
    //         path:"/unpaid",
    //         name:"Unpaid",
    //         icon:<FaFileExcel/>
    //     },
    //     {
    //         path:"/kwitansi",
    //         name:"Kwitansi",
    //         icon:<FaReceipt/>
    //     },
    //     {
    //         path:"/ipllist",
    //         name:"Paid",
    //         icon:<FaFileInvoiceDollar/>
    //     },
    //     {
    //         path:"/masterrate",
    //         name:"Master Rate",
    //         icon:<FaEdit/>
    //     },
    //     {
    //         path:"/reporting",
    //         name:"Laporan",
    //         icon:<BsTable/>
    //     },
    //     {
    //         path:"/uploadbast",
    //         name:"Upload BAST",
    //         icon:<FaFileUpload/>
    //     },
    //     {
    //         path:"/uploadinvoice",
    //         name:"Upload Invoice",
    //         icon:<FaFileUpload/>
    //     },
       
    // ]
}


export const financeConfig = {
    title: {
        text: "Finance",
        icon: <FaDollarSign size={50} />,
        color: `rgb(3, 123, 202)`
    },

    menu: [
        {
            name: "Laporan",
            icon: <HiOutlineDocumentReport/>,
            subMenu: [
                {
                    path:"/reportingipl",
                    name:"IPL & Internet",
                    icon:<MdFactCheck/>
                },
                // {
                //     path:"/uploadbast",
                //     name:"Upload BAST",
                //     icon:<FaFileUpload/>
                // },
            ]
        },
        



       
    ],
}



export const settingConfig = {
    title: {
        text: "Setting",
        icon: <AiOutlineSetting size={50} />,
        color: `rgb(167, 3, 153)`
    },
    menu: [
        {
            name: "Setup Rate",
            icon: <MdPriceChange/>,
            subMenu: [
                // {
                //     path:"/masterrate",
                //     name:"Master Rate",
                //     icon:<FaEdit/>
                // },
                {
                    path:"/editrate",
                    name:"IPL",
                    icon:<FaCommentDollar/>,
                    props:"IPLRate"
                },
                {
                    path:"/editinternetrate",
                    name:"Internet",
                    icon:<MdSignalWifi4Bar/>,
                    props:"InternetRate"
                },
                {
                    path:"/editcustomerrate",
                    name:"Customer Rate",
                    icon:<FaEdit/>,
                },
            ]
        },
        {
            name: "Setup User",
            icon: <MdAdminPanelSettings/>,
            subMenu: [
                // {
                //     path:"/masterrate",
                //     name:"Master Rate",
                //     icon:<FaEdit/>
                // },
                {
                    path:"/newUser",
                    name:"New",
                    icon:<MdAccessibilityNew/>
                }
            ]
        },
        {
            name: "Setup Promo",
            icon: <FaDollarSign/>,
            subMenu: [
                // {
                //     path:"/masterrate",
                //     name:"Master Rate",
                //     icon:<FaEdit/>
                // },
                {
                    path:"/newpromo",
                    name:"Promo IPL",
                    icon:<MdAccessibilityNew/>,
                    props:"IPLRate"
                },
                {
                    path:"/newpromointernet",
                    name:"Promo Internet",
                    icon:<MdAccessibilityNew/>,
                    props:"IPLRate"
                },
            ]
        }
    ]
}